import React, { Fragment, useContext } from 'react'
import { Theme } from 'theme/theme'
import { ReactSVG } from 'react-svg'
import { ImageDeviceLockupStyled } from './ImageDeviceLockup.styles';
import { GlobalContext } from '../../App';

interface ImageDeviceLockupProps {
  theme: Theme;
}

const ImageDeviceLockup = ({ theme }: ImageDeviceLockupProps) => {

  const { assetUrl } = useContext(GlobalContext);

  return (
    <ImageDeviceLockupStyled>
      <ReactSVG
        className="image-device-lockup__main"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${theme?.color?.text || '#ffffff'}`)
        }}
        src={assetUrl + theme?.lockup?.path}
      />
      {!!theme?.icons?.length && theme.icons?.map((icon, i) => {
        // Only add icon if location available
        const location = theme?.lockup?.locations?.[i];
        if (!location) return <Fragment key={i}></Fragment>

        return (<ReactSVG
          key={i}
          className="image-device-lockup__icon"
          beforeInjection={(svg) => {
            svg.setAttribute('style', `fill: ${theme?.color?.text || '#ffffff'};`)
          }}
          style={{
            top: location?.y + '%',
            left: location?.x + '%',
            "--icon-rotation-scale": `rotate(${location?.rotation}deg) scale(${location?.scale})`,
          } as React.CSSProperties}
          src={assetUrl + icon?.path}
        />)
      })}
    </ImageDeviceLockupStyled>
  )
}

export default ImageDeviceLockup;

import React, { Fragment, useContext } from 'react'
import { Theme } from 'theme/theme'
import { ReactSVG } from 'react-svg'
import { ImageDeviceAccentsStyled } from './ImageDeviceAccents.styles';
import { GlobalContext } from '../../App';

interface ImageDeviceAccentsProps {
  theme: Theme;
}

const ImageDeviceAccents = ({ theme }: ImageDeviceAccentsProps) => {

  const { assetUrl } = useContext(GlobalContext);

  return (
    <ImageDeviceAccentsStyled>
      {!!theme?.accents?.length && theme.accents?.map((accent, i) => {
        // Only add accent if location available
        const location = theme?.accentLayout?.locations?.[i];
        if (!location) return <Fragment key={i}></Fragment>

        return (<ReactSVG
          key={i}
          className="image-device-accents__accent"
          beforeInjection={(svg) => {
            svg.setAttribute('style', `fill: ${theme?.color?.text || '#ffffff'};`)
          }}
          style={{
            left: location?.x + '%',
            top: location?.y + '%',
            "--accent-parralax-offset": i / 100,
            "--accent-rotation-scale": `rotate(${location?.rotation}deg) scale(${location?.scale})`,
          } as React.CSSProperties}
          src={assetUrl + accent?.path}
        />)
      })}
    </ImageDeviceAccentsStyled>
  )
}

export default ImageDeviceAccents;

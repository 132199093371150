import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import AppStyled from './App.styles';
import Normalize from '../styles/Normalize';
import Outcome from './molecules/Outcome';
import Question from './molecules/Question';
import Intro from './molecules/Intro';
import { Loading } from './atoms/Loading';
import axios from 'axios';
import AnimatedGrid from './molecules/AnimatedGrid';
import themes, { streamThemes, Theme } from 'theme/theme';
import { cacheImages, throttle } from 'lib/tools';
import Details from './molecules/Details';
import Signup from './molecules/Signup';
import anime from 'animejs';

export interface UserDataModel {
  'FirstName': string;
  'Email': string;
  'levelofeducation': string;
  'residentialStatus': string;
  'optinGlobal': string;
  'Persona': string;
}


const buttonPaths = [
  '/images/buttons/button__background.svg',
  '/images/buttons/button__outline.svg',
  '/images/buttons/arrow-button__background.svg',
  '/images/buttons/arrow-button__outline.svg',
  '/images/buttons/question-button__background--medium.svg',
  '/images/buttons/question-button__background--small.svg',
  '/images/buttons/question-button__background--large.svg',
  '/images/buttons/question-button__outline--small.svg',
  '/images/buttons/question-button__outline--medium.svg',
  '/images/buttons/question-button__outline--large.svg',
  '/images/buttons/image__outline.svg',
  '/images/buttons/checkbox__box.svg',
  '/images/buttons/checkbox__check.svg',
]

const mousePos = {
  x: 0,
  y: 0
}

export interface Scores {
  adventurer: number;
  analyst: number;
  creative: number;
  innovator: number;
  leader: number;
  maker: number;
  people: number;
}


interface GlobalContextProps {
  assetUrl: string;
}

export const GlobalContext = createContext({
  assetUrl: null,
} as GlobalContextProps);

const App = () => {

  const MAX_POINTS_BEFORE_LOST = 55;

  const initialScores = {
    adventurer: 0,
    analyst: 0,
    creative: 0,
    innovator: 0,
    leader: 0,
    maker: 0,
    people: 0
  } as Scores;

  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState<UserDataModel>({
    'FirstName': null,
    'Email': null,
    'levelofeducation': 'Year 11',
    'residentialStatus': null,
    'optinGlobal': null,
    'Persona': null,
  });

  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [assetUrl, setAssetUrl] = useState<string>('');

  const [questions, setQuestions] = useState(null);
  const [outcomes, setOutcomes] = useState(null);
  const [intro, setIntro] = useState(null);

  const [theme, setTheme] = useState(null);

  const [scores, setScores] = useState(initialScores);
  const [answered, setAnswered] = useState([]);

  const fetchQuestions = async () => {
    const origin = process.env.REACT_APP_DATA_URL || (window.location.origin + '/study') || '';
    const dataUrl = origin + '/wp-json/marketforce/v1/careerquiz?v=12';

    await axios.get(dataUrl)
      .then((response) => {
        setQuestions(response.data.questions);
        setOutcomes(response.data.outcomes);
        setIntro(response.data.intro);
        setAssetUrl(response?.data?.url || '')
        return response?.data?.url || ''
      }).then(async (url) => {
        // Preload images
        const iconPaths = themes?.icons?.map(icon => icon?.path);
        const accentPaths = themes?.icons?.map(icon => icon?.path);
        const lockupPaths = themes?.lockups?.map(lockup => lockup?.path);
        const shapesPaths = themes?.shapes?.map(shape => shape?.path);
        const portraitsPaths = themes?.portraits?.map(portrait => portrait?.path);
        const themePortraitPaths = Object.values(streamThemes).map(theme => theme?.portrait?.path);
        // Combine into single array of paths to preload
        const srcArray = [...buttonPaths, ...iconPaths, ...accentPaths, ...lockupPaths, ...shapesPaths, ...portraitsPaths, ...themePortraitPaths];
        await cacheImages(srcArray, url);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error)
      });
  }

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      updateTheme();
      fetchQuestions();
    }

    window.addEventListener('mousemove', debouncedHandleMouseMove);

    return () => {
      window.removeEventListener('mousemove', debouncedHandleMouseMove);
    }
  }, [])

  const handleMouseMove = (ev) => {
    const x = window.innerWidth - ev.clientX;
    const y = window.innerHeight - ev.clientY;
    /* document.body.style.setProperty('--parallax-offset-x', x * 0.005 + 'px');
    document.body.style.setProperty('--parallax-offset-y', y * 0.005 + 'px');
    document.body.style.setProperty('--parallax-rotation', (x + y) * 0.001 + 'deg'); */

    anime({
      targets: mousePos,
      x,
      y,
      easing: 'easeOutQuad',
      duration: 300,
      update: function () {
        document.body.style.setProperty('--parallax-offset-x', mousePos.x * 0.005 + 'px');
        document.body.style.setProperty('--parallax-offset-y', mousePos.y * 0.005 + 'px');
        document.body.style.setProperty('--parallax-rotation', (mousePos.x + mousePos.y) * 0.001 + 'deg');
      }
    });
  }

  const debouncedHandleMouseMove = throttle((ev) => handleMouseMove(ev), 100);

  const resetScores = () => {
    setScores(initialScores);
    setAnswered([]);
  }

  /**
   * Returns random object from array, if current ID is specified attempt to filter it out of array
   * @param arr: Array
   * @param currentId: number 
   * @returns 
   */
  const randomObj = (arr: any[], currentId?: number): any => {
    let tempArr = arr;
    if (currentId || currentId === 0) tempArr = arr.filter(item => item?.id !== currentId);
    return tempArr[Math.floor(Math.random() * tempArr.length)];
  }

  const randomIndex = (arr: any[]): any => {
    return Math.floor(Math.random() * arr.length);
  }

  const updateTheme = (stream = '') => {
    // Find the color by stream name, else use random theme color from colors (without existing color)
    const color = stream ? themes.colors.find(c => c?.stream === stream) : randomObj(themes?.colors, theme?.color?.id);
    const layout = randomObj(themes?.layouts);
    const shape = randomObj(themes?.shapes, theme?.shape?.id);

    // filter out square lockups if selected the triabgle shape
    const filteredLockups = shape?.type === 'tri' ? themes?.lockups.filter(lockup => lockup?.type !== 'square') : themes?.lockups;

    const portrait = randomObj(themes?.portraits, theme?.portrait?.id);
    const lockup = randomObj(filteredLockups, theme?.lockup?.id);
    const accentLayout = randomObj(themes?.accentLayouts, theme?.accentLayout?.id);
    const icons = [];
    const accents = [];

    // Filter icons by stream (inc generic), or use entire icon array
    const filteredIcons = Array.from(stream ? themes?.icons.filter(icon => icon?.stream === stream || icon?.stream === 'generic') : themes?.icons);

    lockup.locations.forEach(() => {
      icons.push(filteredIcons.splice(randomIndex(filteredIcons), 1)?.[0])
    });

    const filteredAccents = Array.from(themes?.accents);

    accentLayout.locations.forEach(() => {
      accents.push(filteredAccents.splice(randomIndex(filteredAccents), 1)?.[0])
    });

    // Create new theme
    setTheme({
      layout,
      shape,
      portrait,
      lockup,
      color,
      icons,
      accentLayout,
      accents,
    })
  }

  // get the highest score
  const getHighestScore = (mergedScores) => {
    return mergedScores[getHighestScoreKey(mergedScores)];
  }

  const getHighestScoreKey = (mergedScores) => {
    return Object.keys(mergedScores).sort((a, b) => mergedScores[a] > mergedScores[b] ? -1 : 0)[0];
  }

  const getHighestScoreOutcomeId = (mergedScores) => {
    const finalKey = getHighestScoreKey(mergedScores);
    return Object.keys(outcomes).filter((key) => outcomes[key].stream === finalKey)[0];
  }

  const hasAnsweredQuestion = (qid) => {
    return answered.filter((answer) => answer == qid).length > 0;
  }

  const getNextQuestion = (qid) => {

    if (!hasAnsweredQuestion(qid)) {
      return qid;
    }

    const next = questions[qid];

    if (next.isFinal) {
      return next.id;
    }

    if (next.nextId) {
      return getNextQuestion(next.nextId);
    }

    // it should never get here
    return qid;

  }

  const answerQuestion = (qid, answer, isFinal) => {

    const newScores = {};

    if (answer.score) {
      // add the new scores from the answer to our existing scores
      Object.keys(answer.score).map((key) => {
        newScores[key] = scores[key] + answer.score[key];
      });
    }

    setAnswered([qid, ...answered]);

    const mergedScores = { ...scores, ...newScores };

    setScores(mergedScores);

    if (isFinal || getHighestScore(mergedScores) > MAX_POINTS_BEFORE_LOST) {
      // console.log('GOTO', getHighestScoreOutcomeId(mergedScores));
      updateTheme();
      navigate('/signup/' + getHighestScoreOutcomeId(mergedScores), { replace: true });
    } else if (answer.linkedId) {
      updateTheme();
      navigate('/question/' + getNextQuestion(answer.linkedId), { replace: true });
    }

  }


  return (
    <AppStyled id='app'>
      <GlobalContext.Provider value={{ assetUrl }}>
        <Normalize />
        <AnimatedGrid bgColor={(location?.pathname === '/' ? streamThemes?.intro?.color?.background : theme?.color?.background) || '#fff'} />
        {isLoading &&
          <Loading />
        }
        {!isLoading &&
          <>
            {/* <ul className='scores'>
            {Object.keys(scores).map((key, i) => <li key={'score-' + i}>{key} : {scores[key]}</li> )}
            <li>Answered: {answered.length ?? 0}</li>
          </ul> */}

            <div className='container'>

            {/* <div className='test-buttons' style={{ position: 'absolute', zIndex: '999', top: '10px', left: '10px' }}>
              <button style={{ marginRight: '0.5rem' }} onClick={() => {
                const newQ = randomObj(Object.keys(questions));
                console.log('Random Q! ', newQ)
                navigate('/question/' + newQ);
              }}>Random Q</button>
              <button style={{ marginRight: '0.5rem' }} onClick={() => {
                updateTheme();
              }}>Randomize Theme</button>
              <button style={{ marginRight: '0.5rem' }} onClick={() => {
                const newO = randomObj(Object.keys(outcomes))
                navigate('/outcome/' + newO);
                updateTheme(outcomes[newO]?.stream);
              }}>Random Outcome</button>
              <button style={{ marginRight: '0.5rem' }} onClick={() => {
                navigate('/signup/69');
                updateTheme();
              }}>Signup!</button>
            </div>  */}

              <TransitionGroup>
                <CSSTransition
                  key={location.pathname}
                  timeout={500}
                  classNames={'fade'}
                >
                  <Routes location={location}>
                    <Route path='/' element={
                      <div className="stage">
                        <Intro
                          intro={intro}
                          theme={streamThemes?.intro}
                        />
                      </div>}>
                    </Route>
                    <Route path='/details' element={
                      <div className="stage">
                        <Details
                          questions={questions}
                          theme={theme}
                          userData={userData}
                          setUserData={setUserData}
                        />
                      </div>}>
                    </Route>
                    <Route path='/question/:qid' element={
                      <div className="stage">
                        <Question
                          questions={questions}
                          answerQuestion={answerQuestion}
                          theme={theme}
                        />
                      </div>}>
                    </Route>
                    <Route path='/signup/:oid' element={
                      <div className="stage">
                        <Signup
                          outcomes={outcomes}
                          theme={theme}
                          scores={scores}
                          userData={userData}
                          setUserData={setUserData}
                        />
                      </div>
                    }>
                    </Route>
                    <Route path='/outcome/:oid' element={
                      <div className="stage">
                        <Outcome
                          outcomes={outcomes}
                          updateTheme={updateTheme}
                          resetScores={resetScores}
                          theme={theme}
                          streamThemes={streamThemes}
                        />
                      </div>
                    }>
                    </Route>
                  </Routes>

                </CSSTransition>
              </TransitionGroup>

            </div>
          </>
        }
      </GlobalContext.Provider>
    </AppStyled>
  )
}

export default App;

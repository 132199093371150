import React, { useContext } from 'react'
import { SketchButtonArrowStyled } from './SketchButtonArrow.styles';
import { ReactSVG } from 'react-svg'
import { SketchButtonProps } from '../SketchButton';
import { GlobalContext } from '../../App';

const SketchButtonArrow = ({ label, text, type = 'button', outlineColor = '#fff', textColor = '#fff', textHoverColor, backgroundColor = '#5824E1', clickHandler }: SketchButtonProps) => {
  if (!textHoverColor) textHoverColor = textColor;

  const { assetUrl } = useContext(GlobalContext);

  return (
    <SketchButtonArrowStyled
      aria-label={label || text}
      type={type}
      onClick={clickHandler}
      textHoverColor={textHoverColor}
    >
      <ReactSVG
        className="sketch-button__background"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${backgroundColor}`)
        }}
        src={assetUrl + '/images/buttons/arrow-button__background.svg'}
      />
      <span style={{ color: textColor }}>
        {text}
      </span>
      <ReactSVG
        className="sketch-button__outline"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${outlineColor}`)
        }}
        src={assetUrl + '/images/buttons/arrow-button__outline.svg'}
      />
    </SketchButtonArrowStyled>
  )
}

export default SketchButtonArrow;

import styled from 'styled-components';

export const LoadingStyled = styled.div`    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
`

export default LoadingStyled;
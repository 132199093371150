import styled from 'styled-components';
import { media } from './../../../lib/mediaQueries';

interface SliderStyledProps {
  color: string;
  answerCount: number;
}

export const SliderStyled = styled.div<SliderStyledProps>`
    width: 100%;
    margin-bottom: 6rem;
    --color: ${props => props?.color || '#fff'};
    --rail-height: 90px;
    --rail-padding: 4px;
    --rail-border-size: 2px;
    
    .rc-slider {
      height: var(--rail-height);
      border: var(--rail-border-size) solid var(--color);
      border-radius: 0;
      padding: 0;
    }

    .rc-slider-track {
      clip-path: inset(8px);
      background-color: var(--color);
      transition-property: width;
      transition-duration: 350ms;
      transition-timing-function: cubic-bezier(0.24, -0.01, 0, 1.23)
    }

    .rc-slider-rail {
      background: none;
    }

    .rc-slider-track,
    .rc-slider-rail {
      height: calc(var(--rail-height) - (var(--rail-border-size) * 2));
      top: 0;
    }

    .rc-slider-handle {
      height: calc(var(--rail-height) + 20px);
      top: -8px;
      width: 40px;
      border-radius: 0;
      background: transparent;
      opacity: 1;
      border: 0 !important;
      box-shadow: none !important;
      transition: 0.1s transform linear;

      :hover {
        svg {
          transform: skew(360deg, 1deg) rotate3d(0, 0, 1, 180deg) scale(0.9, 0.95);
        }
      }

      &-dragging {
        box-shadow: none !important;
        transform: translateX(-50%) scale(1.1) !important;
        
        svg {
          transform: skew(360deg, 1deg) rotate3d(0, 0, 1, 180deg) scale(0.9, 0.95);
        }
      }

      :focus-visible {
        outline: 2px dashed #000;
        border-radius: 4px;
        outline-offset: 3px;
      }
    }

    .rc-slider-step {
      top: unset;
      bottom: 0;
    }

    .rc-slider-dot {
      width: 3px;
      border-radius: 0px;
      background: var(--color);
      border: none;
      height: 5px;
      bottom: 0;

      &:first-child,
      &:last-child {
        display: none;
      }

    }

    .slider__value {
      color: var(--color);
      ${media.greaterThan('md')`
        display: none;
      `}
    }

    .rc-slider-mark {
      display: none;

      ${media.greaterThan('md')`
        display: block;
        top: initial;
        bottom: -20px;
        counter-reset: count;
      `}
    }

    .rc-slider-mark-text {
      display: block;
      color: var(--color);
      font-size: 1.25rem;
      padding: 0rem 1rem;
      font-weight: 600;
      width: max-content;
      max-width: ${props => ((100 / props?.answerCount) - 1.5 + '%') || '180px'};


      &:first-child {
        padding-left: 0rem;
        transform: translateX(0) !important;
      }

      &:last-child {
        padding-right: 0rem;
        transform: translateX(-100%) !important;
      }
    }

    
`

export default SliderStyled;
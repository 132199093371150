import styled from 'styled-components';
import { media } from './../../../lib/mediaQueries';

export const ImageDeviceStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: visible;
  align-self: flex-end;
  aspect-ratio: 1 / 1;

  ${media.greaterThan('lg')`
    position: absolute;
    width: min(50vw, 100vh);
    right: 0;
    bottom: 0;

    div.question--left & {
      left: 0;
      right: unset;
    }
  `}

  & > img, & > div {
    width: 100%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 1/1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.greaterThan('md')`
      top: unset;
      bottom: 0;
    `}

    ${media.greaterThan('lg')`
      height: 100%;
    `}
  }

  .image-device__accents {
    align-items: flex-end;
    transform: translate(calc(var(--parallax-offset-x) * 1.5), calc(var(--parallax-offset-y) * 1.5)) rotate(calc(var(--parallax-rotation)));
  }

  .image-device__lockup {
    > div {
      position: absolute;
      top: 0;
      height: min-content;
      width: 100%;
      transform: translate(calc(var(--parallax-offset-x) * 2.5), calc(var(--parallax-offset-y) * 2.5 - 5%)) rotate(calc(var(--parallax-rotation)));

      ${media.greaterThan('md')`
        position: relative;
        top: unset;
      `}

      ${media.greaterThan('lg')`
        bottom: 5%;
      `}
    }
  }

  .image-device__shape {
    > div {
      position: absolute;
      top: 0;
      height: min-content;
      width: 100%;
      transform: translate(calc(var(--parallax-offset-x) * 1.5), calc(var(--parallax-offset-y) * 1.5));
      transition: opacity 300ms;

      div.fade-enter & {
        opacity: 0;
      }

      div.fade-enter-active & {
        opacity: 1;
      }

      div.fade-exit & {
        opacity: 1;
      }

      div.fade-exit-active & {
        opacity: 0;
      }

      ${media.greaterThan('md')`
        position: relative;
        top: unset;
      `}
      
      ${media.greaterThan('lg')`
        bottom: 5%;
      `}
    }
  }

  .image-device__portrait {
    transform: translate(calc(var(--parallax-offset-x) * 3), calc(var(--parallax-offset-y) * 3));

    & > img {
      position: absolute;
      top: 0;
      width: auto;
      height: 96%;
      transition-property: opacity transform -webkit-transform;
      transition-timing-function: ease-out;
      transition-duration: 300ms;

      ${media.greaterThan('md')`
        top: unset;
        bottom: 0;
      `}
    }

    div.fade-enter & > img {
      transform: translateY(30%);
      opacity: 0;
    }

    div.fade-enter-active & > img {
      transform: translateY(0%);
      transition-delay: 100ms;
      opacity: 1;
    }

    div.fade-exit & > img {
      transform: translateY(0%);
      transition-delay: 100ms;
      opacity: 1;
    }

    div.fade-exit-active & > img {
      transform: translateY(30%);
      opacity: 0;
    }

  }

`
import styled from 'styled-components';

interface SketchInputStyledProps {
  textColor: string;
  textHoverColor: string;
}

export const SketchInputStyled = styled.div<SketchInputStyledProps>`
  all: unset;
  position: relative ;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1.3rem;
  font-weight: bold;
  justify-items: center;
  justify-self: left;

  .sketch-button__outline {
    position: absolute;
    top: 0;
    left: 0;
  }

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 2rem;
    z-index: 2;
    font-size: 2rem;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: ${props => props?.textColor || '#fff'};


    ::placeholder {
      color: ${props => props?.textColor || '#fff'};
    }

    :focus-visible {
      outline: 2px dashed ${props => props?.textColor || '#fff'};
      border-radius: 4px;
      outline-offset: 2px;
    }

  }

  .sketch-button__background {
    transform: skew(16deg, -1deg) scaleY(0.9);
    transform: skew(16deg,355deg) scaleY(0.95) rotate(5deg);
    opacity: 0;
    /* clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    transition-property: clip-path;
    transition-timing-function: cubic-bezier(0.55, 0.57, 0, 1.03);
    transition-duration: 250ms; */
  }

  :hover, :active {
    .sketch-button__background {
      opacity: 1;
      /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); */
    }
    .sketch-button__outline {
      transform: skew(1deg,1deg) rotate3d(1,0,0,170deg) scaleY(1) translateY(3px);

      svg {
        fill: #000 !important;
      }
    }
    
    span {
      color: ${props => props?.textHoverColor || '#fff'} !important;
    }

    input {
      color: ${props => props?.textHoverColor || '#fff'};

      ::placeholder {
        color: ${props => props?.textHoverColor || '#fff'};
        opacity: 1;
      }

      :focus-visible {
        outline-color: '#000';
      }
    }
  }

  svg {
    width: 100%;
    height: auto;
  }
`
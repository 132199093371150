import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SketchButton from '../../atoms/SketchButton';
import { Theme } from 'theme/theme';
import AnimatedText from '../../atoms/AnimatedText';
import { QuestionStyled } from '../Question/Question.styles';
import ImageDevice from '../ImageDevice';
import { IntroStyled } from './Intro.styles';

interface IntroProps {
  theme: Theme;
  intro?: {
    title?: string;
    content?: string;
  }
}

export const Intro = ({intro, theme}: IntroProps) => {
  
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    navigate('/details', {replace: true});
  }, [navigate]);

  
  return (
    <IntroStyled>
      <QuestionStyled className='question question--left'>
        <div className='question__content'>
          <h1 style={{ color: theme?.color?.text || '#fff' }}><AnimatedText text={intro.title} /></h1>
          {intro.content && 
            <div
              style={{ color: theme?.color?.text || '#fff' }}
              dangerouslySetInnerHTML={{__html: intro.content}}
            />
          }
          <SketchButton
            text={'Start'}
            label={'Start'}
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
            clickHandler={() => handleOnClick()}
          />
        </div>
        <div className='question__image'>
          <ImageDevice theme={theme} />
        </div>
      </QuestionStyled>
    </IntroStyled>
  )
}

export default Intro;
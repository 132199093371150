// Alternatively, consider a css-variables approach. If intended browserstack allows for it.

/* FLUID TYPE */
import { BREAKPOINTS } from './mediaQueries';
import { getUnit, getValue } from './tools';

// Hattip, Mike Riethmuller for original formula. Mandy Michaels for initial JS version. And me for polishing it up :P
/* 
Usage: 
  import { BREAKPOINTS } from "../lib/mediaQueries";
  import { fluidType } from "../lib/FluidType";

  ${fluidType({lowestFontSize: "1em", highestFontSize: "2em", smallestScreenWidth: BREAKPOINTS.tablet, widestScreenWidth: BREAKPOINTS.desktop})}
*/

export const calcInterpolation = ({
  lowestSize,
  highestSize,
  smallestScreenWidth,
  widestScreenWidth,
}) => {
  const u1 = getUnit(smallestScreenWidth);
  const u2 = getUnit(widestScreenWidth);
  const u3 = getUnit(lowestSize);
  const u4 = getUnit(highestSize);
  if (u1 === u2 && u3 === u4 && u1 === u3) {
    const minScreen = getValue(smallestScreenWidth);
    const maxScreen = getValue(widestScreenWidth);
    const minValue = getValue(lowestSize);
    const maxValue = getValue(highestSize);

    return `calc(${lowestSize} + ${
      maxValue - minValue
    } * ((100vw - ${smallestScreenWidth}) / ${maxScreen - minScreen}));`;
  } else {
    throw new Error(
      `Please use the same units (px/em/rem/etc)\n${u1} from ${smallestScreenWidth}, ${u2} from ${widestScreenWidth}, ${u3} from ${lowestSize}, ${u4} from ${highestSize}`
    );
  }
};


export const fluidProperty = ({
  property,
  lowestSize,
  highestSize,
  smallestScreenWidth=BREAKPOINTS.tablet,
  widestScreenWidth=BREAKPOINTS.desktop,
}) => {
  const result = `
    ${property}: ${lowestSize};
    @media screen and (min-width: ${smallestScreenWidth}) {
      ${property}: ${calcInterpolation({
        smallestScreenWidth,
        widestScreenWidth,
        lowestSize,
        highestSize,
      })};
    }
    @media screen and (min-width: ${widestScreenWidth}) {
      ${property}: ${highestSize};
    }
  `;
  return result;
};

import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignupStyled } from './Signup.styles';
import { Theme } from 'theme/theme';
import SketchInput from '../../atoms/SketchInput';
import SketchButtonQuestion from '../../atoms/SketchButtonQuestion';
import { useForm } from 'react-hook-form';
import { Scores, UserDataModel } from "../../App";
import SketchCheckbox from '../../atoms/SketchCheckbox';
import SketchRadio from '../../atoms/SketchRadio';
import { capitalizeFirstLetter } from 'lib/tools';

interface SignupProps {
  theme: Theme;
  scores: Scores;
  userData: UserDataModel;
  setUserData: Dispatch<SetStateAction<UserDataModel>>;
  outcomes: [
    {
      id: number;
      stream: string;
      content?: string;
    }
  ]
}

export const Signup = ({outcomes, theme, scores, userData, setUserData}: SignupProps) => {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      notify: true,
      resident: '',
      email: '',
    }
  });

  const getHighestScoreKey = (mergedScores) => {
    return Object.keys(mergedScores).sort((a, b) => mergedScores[a] > mergedScores[b] ? -1 : 0)[0];
  }

  const getHighestScoreOutcomeId = (mergedScores) => {
    const finalKey = getHighestScoreKey(mergedScores);
    return Object.keys(outcomes).filter((key) => outcomes[key].stream === finalKey)[0];
  }

  const outcomeId = useMemo(() => getHighestScoreOutcomeId(scores), [scores]);

  const outcome = useMemo(() => {
    return capitalizeFirstLetter(outcomes[outcomeId]?.stream || '');
  }, [outcomeId])

  const onSubmit = async (data) => {
    setLoading(true)

    const submissionData = {
      ...userData,
      'optinGlobal': data?.notify ? 'yes' : 'no',
      'Email': data?.email,
      'residentialStatus': data?.resident,
      'Persona': outcome === 'People' ? 'People person' : outcome,
    }

    setUserData(submissionData);

    const form = (window as any).MktoForms2.getForm('5284')

    if (!form) {
      setLoading(false)
      setMessage('Unable to submit, please try again later.')
      return false
    }

    form.vals(submissionData)
    .onSuccess(() => {
      setLoading(false);
      navigate('/outcome/' + outcomeId, {replace: true});
      return false
    })
    .submit();
  }
  
  return (
    <SignupStyled color={theme?.color?.text || '#000'}  colorTextHover={theme?.color?.textHover || '#fff'}  colorAccent={theme?.color?.accent || '#000'} onSubmit={handleSubmit(onSubmit)}>
      <h1>Great job! Thank you for your answers.<br/>Sign up here to get your personalised report.</h1>
      <div className='signup__inputs'>
        <div className='signup__input'>
          <SketchInput
            label="Email address"
            placeholder="Email address"
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
          >
            <input
              placeholder="Email address"
              aria-label="Email address"
              {...register("email", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i})} 
            />
          </SketchInput>
          {errors.email && errors.email.type === "required" && <span>Required</span>}
          {errors.email && errors.email.type === "pattern" && <span>Please add a valid email</span> }
        </div>
        <div className='resident__input'>
          <p>Citizenship of student.</p>
          <div>
            <SketchRadio
              text='Australian or New Zealand citizen'
              label='Australian or New Zealand citizen'
              textColor={theme?.color?.text}
              textHoverColor={theme?.color?.textHover || '#fff'}
              outlineColor={theme?.color?.text}
              backgroundColor={theme?.color?.accent}
              active={watch('resident') === 'Australian Citizen'}
            >
              <input
                type="radio"
                value='Australian Citizen'
                aria-label="Australian or New Zealand citizen"
                {...register("resident", {required: true})} 
              />
            </SketchRadio>
            <SketchRadio
              text='International Onshore'
              label='International Onshore'
              textColor={theme?.color?.text}
              textHoverColor={theme?.color?.textHover || '#fff'}
              outlineColor={theme?.color?.text}
              backgroundColor={theme?.color?.accent}
              active={watch('resident') === 'International Onshore'}
            >
              <input
                type="radio"
                value="International Onshore"
                aria-label="International Onshore"
                {...register("resident", {required: true})} 
              />
            </SketchRadio>
          </div>
          {errors.resident && <span>Please select your citizenship status</span> }
        </div>
        <div className='signup__input'>
          <label>
            <SketchCheckbox
              textColor={theme?.color?.text}
              textHoverColor={theme?.color?.textHover || '#0d0d0d'}
              outlineColor={theme?.color?.text}
              backgroundColor={theme?.color?.accent}
              active={watch('notify')}
            >
              <input
                type="checkbox"
                aria-label="Signup for more info"
                {...register('notify', {required: false})} 
              />
            </SketchCheckbox>
            <p>I would like to receive more information from Curtin University.</p>
          </label>
        </div>
        <div>
          <p>Personal information collected is handled in accordance with the Australian Privacy Principles.</p>
          <p>For further information please consult Curtin University’s <a href="https://informationmanagement.curtin.edu.au/privacy/" target="_blank" rel="noreferrer">Privacy Statement</a>.</p>
        </div>
        <div>
          {!!message && <p style={{ fontWeight: 'bold' }}>{message}</p>}
          <SketchButtonQuestion
            text={loading ? 'Submitting' : 'Submit'}
            label={'Submit'}
            type="submit"
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
          />
        </div>
      </div>
    </SignupStyled>
  )
}

export default Signup;
import { fluidProperty } from 'lib/FluidProperty';
import styled from 'styled-components';
import { SketchButtonStyledProps } from '../SketchButton/SketchButton.styles';
import { media } from './../../../lib/mediaQueries';

export const SketchButtonQuestionStyled = styled.button<SketchButtonStyledProps>`
  all: unset;
  position: relative ;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1.3rem;
  font-weight: bold;
  justify-items: center;
  justify-self: left;
  container: button;
  container-type: normal;

  .sketch-button__outline {
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    line-height: 1.1;
    font-size: 1rem;
    padding: 1.5rem;
  }

  ${media.greaterThan('xs')`
    span {
      font-size: 1.5rem;
      padding: 3rem;
    }
  `}

  ${media.greaterThan('lg')`
    span {
      font-size: 1.3rem;
      padding: 3rem;
    }
  `}

  .sketch-button__background {
    transform: skew(16deg, -1deg) scaleY(0.9);
    transform: skew(16deg,355deg) scaleY(0.95) rotate(5deg);
    opacity: 0;
    /* clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    transition-property: clip-path;
    transition-timing-function: cubic-bezier(0.55, 0.57, 0, 1.03);
    transition-duration: 250ms; */
  }

  :hover, :active {
    .sketch-button__background {
      opacity: 1;
      /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); */
    }
    .sketch-button__outline {
      transform: skew(1deg,1deg) rotate3d(1,0,0,170deg) scaleY(1) translateY(3px);

      svg {
        fill: #000 !important;
      }
    }

    span {
      color: ${props => props?.textHoverColor || '#fff'} !important;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }

  :focus-visible {
    outline: 2px dashed #000;
    border-radius: 4px;
    outline-offset: 3px;
  }
`
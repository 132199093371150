import styled from 'styled-components';


export const OutcomeStyled = styled.div`
  > div {
    .question__content {
      & {
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
      }

      h1 {
        & {
          font-size: 3rem;
          margin: 0 0 2rem 0;
          padding: 0;
          text-align: left;
        }
      }

      h2 {
        font-size: 1.4rem;
        margin: 0 0 2rem 0;
      }

      .outcome__description {
        text-align: left;
        line-height: 1.5;
        margin: 0 0 2rem 0;
      }

      .outcome__traits {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        gap: 1.25rem;
        margin: 0 0 3rem 0;
        width: 100%;
      }

      .outcome__areas {
        display: grid;
        grid-template-columns: minmax(0, 50%) minmax(0, 50%);
        gap: 1.25rem;
        margin: 0 0 3rem 0;
        width: 100%;
      }

      .outcome__links {
        >div {
          display: grid;
          grid-template-columns: minmax(0, 50%) minmax(0, 50%);
          gap: 1.25rem;
          margin: 0 0 3rem 0;
        }
      }

      >button {
        margin: 2rem 0;
      }
    }
  }

  p {
    font-size: 1.5rem !important;
    margin: 1rem 0 !important;
    line-height: 1 !important;
  }

`
import React, { ReactNode, useContext } from 'react'
import { SketchRadioStyled } from './SketchRadio.styles';
import { ReactSVG } from 'react-svg'
import { SketchButtonProps } from '../SketchButton';
import { GlobalContext } from '../../App';

interface SketchRadioProps extends SketchButtonProps {
  active?: boolean;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  children?: ReactNode;
}

const SketchRadio = ({ label, text, type = 'button', outlineColor = '#fff', textColor = '#fff', textHoverColor, backgroundColor = '#5824E1', clickHandler, active, value, setValue, children }: SketchRadioProps) => {
  if (!textHoverColor) textHoverColor = textColor;

  const { assetUrl } = useContext(GlobalContext);

  return (
    <>
      <SketchRadioStyled
        className={`sketch-radio sketch-radio--small ${active ? 'sketch-radio--active' : ''}`}
        aria-label={label}
        type={type}
        onClick={clickHandler}
      >

        {children || <input
          type='radio'
          aria-label={label}
          onChange={() => { if (setValue) setValue(value) }}
        />}

          <ReactSVG
            className="sketch-button__background"
            beforeInjection={(svg) => {
              svg.setAttribute('style', `fill: ${backgroundColor}`)
            }}
            src={assetUrl + `/images/buttons/question-button__background--small.svg`}
          />
          <span style={{ color: textColor }}>
            {text}
          </span>
          <ReactSVG
            className="sketch-button__outline"
            beforeInjection={(svg) => {
              svg.setAttribute('style', `fill: ${outlineColor}`)
            }}
            src={assetUrl + `/images/buttons/question-button__outline--small.svg`}
          />
      </SketchRadioStyled>
    </>
  )
}

export default SketchRadio;

const themes = {
  layouts: ['image-left', 'image-right', 'no-image'],
  icons: [
    {
      id: 0,
      stream: 'generic',
      path: '/images/icons/icon_generic_1.svg',
    },
    {
      id: 1,
      stream: 'generic',
      path: '/images/icons/icon_generic_2.svg',
    },
    {
      id: 2,
      stream: 'generic',
      path: '/images/icons/icon_generic_3.svg',
    },
    {
      id: 3,
      stream: 'generic',
      path: '/images/icons/icon_generic_4.svg',
    },
    {
      id: 4,
      stream: 'generic',
      path: '/images/icons/icon_generic_5.svg',
    },
    {
      id: 5,
      stream: 'generic',
      path: '/images/icons/icon_generic_6.svg',
    },
    {
      id: 6,
      stream: 'leader',
      path: '/images/icons/icon_leader_1.svg',
    },
    {
      id: 7,
      stream: 'leader',
      path: '/images/icons/icon_leader_2.svg',
    },
    {
      id: 8,
      stream: 'leader',
      path: '/images/icons/icon_leader_3.svg',
    },
    {
      id: 9,
      stream: 'leader',
      path: '/images/icons/icon_leader_4.svg',
    },
    {
      id: 10,
      stream: 'leader',
      path: '/images/icons/icon_leader_5.svg',
    },
    {
      id: 11,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_1.svg',
    },
    {
      id: 12,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_2.svg',
    },
    {
      id: 13,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_3.svg',
    },
    {
      id: 14,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_4.svg',
    },
    {
      id: 15,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_5.svg',
    },
    {
      id: 16,
      stream: 'creative',
      path: '/images/icons/icon_creative_1.svg',
    },
    {
      id: 17,
      stream: 'creative',
      path: '/images/icons/icon_creative_2.svg',
    },
    {
      id: 18,
      stream: 'creative',
      path: '/images/icons/icon_creative_3.svg',
    },
    {
      id: 19,
      stream: 'creative',
      path: '/images/icons/icon_creative_4.svg',
    },
    {
      id: 20,
      stream: 'creative',
      path: '/images/icons/icon_creative_5.svg',
    },
    {
      id: 21,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_1.svg',
    },
    {
      id: 22,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_2.svg',
    },
    {
      id: 23,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_3.svg',
    },
    {
      id: 24,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_4.svg',
    },
    {
      id: 25,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_5.svg',
    },
    {
      id: 26,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_1.svg',
    },
    {
      id: 27,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_2.svg',
    },
    {
      id: 28,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_3.svg',
    },
    {
      id: 29,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_4.svg',
    },
    {
      id: 30,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_5.svg',
    },
    {
      id: 31,
      stream: 'maker',
      path: '/images/icons/icon_maker_1.svg',
    },
    {
      id: 32,
      stream: 'maker',
      path: '/images/icons/icon_maker_2.svg',
    },
    {
      id: 33,
      stream: 'maker',
      path: '/images/icons/icon_maker_3.svg',
    },
    {
      id: 34,
      stream: 'maker',
      path: '/images/icons/icon_maker_4.svg',
    },
    {
      id: 35,
      stream: 'maker',
      path: '/images/icons/icon_maker_5.svg',
    },
    {
      id: 36,
      stream: 'people',
      path: '/images/icons/icon_people_1.svg',
    },
    {
      id: 37,
      stream: 'people',
      path: '/images/icons/icon_people_2.svg',
    },
    {
      id: 38,
      stream: 'people',
      path: '/images/icons/icon_people_3.svg',
    },
    {
      id: 39,
      stream: 'people',
      path: '/images/icons/icon_people_4.svg',
    },
    {
      id: 40,
      stream: 'people',
      path: '/images/icons/icon_people_5.svg',
    },
  ],
  lockups: [
    {
      id: 0,
      type: 'circle',
      path: '/images/lockups/lockup_circle_1.svg',
      locations: [
        {
          x: 22,
          y: 4,
          rotation: -20,
          scale: 1,
        },
        {
          x: 2,
          y: 36,
          rotation: -43,
          scale: 1,
        },
        {
          x: 77,
          y: 18,
          rotation: 38,
          scale: 1,
        }
      ],
    },
    {
      id: 1,
      type: 'circle',
      path: '/images/lockups/lockup_circle_2.svg',
      locations: [
        {
          x: 6,
          y: 24,
          rotation: -35,
          scale: 1,
        },
        {
          x: 58,
          y: 4,
          rotation: 27,
          scale: 0.9,
        },
        {
          x: 82,
          y: 34,
          rotation: 31,
          scale: 0.9,
        }
      ],
    },
    {
      id: 6,
      type: 'circle',
      path: '/images/lockups/lockup_circle_4.svg',
      locations: [
        {
          x: 8,
          y: 58,
          rotation: -10,
          scale: 0.9,
        },
        {
          x: 52,
          y: 0,
          rotation: 27,
          scale: 0.9,
        },
        {
          x: 82,
          y: 56,
          rotation: 15,
          scale: 1,
        }
      ],
    },
    {
      id: 2,
      type: 'square',
      path: '/images/lockups/lockup_square_1.svg',
      locations: [
        {
          x: 83,
          y: 26,
          rotation: 11,
          scale: 1,
        },
        {
          x: 3,
          y: 17,
          rotation: -6,
          scale: 1,
        },
        {
          x: 55,
          y: 1,
          rotation: -3,
          scale: 1,
        },
      ],
    },
    {
      id: 3,
      type: 'square',
      path: '/images/lockups/lockup_square_2.svg',
      locations: [
        {
          x: 83,
          y: 18,
          rotation: 53,
          scale: 1,
        },
        {
          x: 27,
          y: 1,
          rotation: 4,
          scale: 1,
        },
        {
          x: 2,
          y: 25,
          rotation: -10,
          scale: 1,
        },
      ],
    },
    {
      id: 4,
      type: 'tri',
      path: '/images/lockups/lockup_tri_1.svg',
      locations: [
        {
          x: 8,
          y: 26,
          rotation: -10,
          scale: 1,
        },
        {
          x: 17,
          y: 2,
          rotation: -2,
          scale: 1,
        },
        {
          x: 78,
          y: 20,
          rotation: 16,
          scale: 1,
        },
      ]
    },
    {
      id: 5,
      type: 'tri',
      path: '/images/lockups/lockup_tri_2.svg',
      locations: [
        {
          x: 74,
          y: 26,
          rotation: 53,
          scale: 1,
        },
        {
          x: 66,
          y: 3,
          rotation: 28,
          scale: 1,
        },
        {
          x: 6,
          y: 21,
          rotation: -22,
          scale: 1,
        },
      ]
    }
  ],
  accents: [
    {
      id: 0,
      path: '/images/accents/accent-1.svg',
    },
    {
      id: 1,
      path: '/images/accents/accent-2.svg',
    },
    {
      id: 2,
      path: '/images/accents/accent-3.svg',
    },
    {
      id: 3,
      path: '/images/accents/accent-4.svg',
    },
    {
      id: 4,
      path: '/images/accents/accent-5.svg',
    },
    {
      id: 5,
      path: '/images/accents/accent-6.svg',
    },
    {
      id: 6,
      path: '/images/accents/accent-7.svg',
    },
    {
      id: 7,
      path: '/images/accents/accent-8.svg',
    },
    {
      id: 8,
      path: '/images/accents/accent-9.svg',
    },
    {
      id: 9,
      path: '/images/accents/accent-10.svg',
    },
    {
      id: 10,
      path: '/images/accents/accent-11.svg',
    },
    {
      id: 11,
      path: '/images/accents/accent-12.svg',
    },
    {
      id: 12,
      path: '/images/accents/accent-13.svg',
    },
    {
      id: 13,
      path: '/images/accents/accent-14.svg',
    },
    {
      id: 14,
      path: '/images/accents/accent-15.svg',
    },
  ],
  accentLayouts: [
    {
      id: 0,
      locations: [
        {
          x: 27,
          y: 76,
          rotation: -40,
          scale: 1,
        },
        {
          x: 74,
          y: 39,
          rotation: 20,
          scale: 1,
        },
      ]
    },
    {
      id: 1,
      locations: [
        {
          x: 75,
          y: 40,
          rotation: 80,
          scale: 1,
        },
        {
          x: 10,
          y: 70,
          rotation: -110,
          scale: 1,
        },
      ]
    },
    {
      id: 2,
      locations: [
        {
          x: 5,
          y: 48,
          rotation: -25,
          scale: 1,
        },
      ]
    },
  ],
  shapes: [
    {
      id: 0,
      type: 'circle',
      path: '/images/shapes/shape_circle.svg',
    },
    {
      id: 1,
      type: 'square',
      path: '/images/shapes/shape_square.svg',
    },
    {
      id: 2,
      type: 'tri',
      path: '/images/shapes/shape_triangle.svg',
    }
  ],
  portraits: [
    {
      id: 1,
      path: '/images/portraits/portrait-1__1.webp',
    },
    {
      id: 2,
      path: '/images/portraits/portrait-2__3.webp',
    },
    {
      id: 3,
      path: '/images/portraits/portrait-5__3.webp',
    },
    {
      id: 4,
      path: '/images/portraits/portrait-6__2.webp',
    },
    {
      id: 5,
      path: '/images/portraits/portrait-1__3.webp',
    },
    {
      id: 6,
      path: '/images/portraits/portrait-4__3.webp',
    },
    {
      id: 7,
      path: '/images/portraits/portrait-5__2.webp',
    },
    {
      id: 8,
      path: '/images/portraits/portrait-8__1.webp',
    },
    {
      id: 9,
      path: '/images/portraits/portrait-3__3.webp',
    },
    {
      id: 10,
      path: '/images/portraits/portrait-4__2.webp',
    },
    {
      id: 11,
      path: '/images/portraits/portrait-7__3.webp',
    },
    {
      id: 12,
      path: '/images/portraits/portrait-8__2.webp',
    },
    {
      id: 13,
      path: '/images/portraits/portrait-2__1.webp',
    },
    {
      id: 14,
      path: '/images/portraits/portrait-3__2.webp',
    },
    {
      id: 15,
      path: '/images/portraits/portrait-6__1.webp',
    },
    {
      id: 16,
      path: '/images/portraits/portrait-7__2.webp',
    },
  ],
  colors: [
    {
      id: 0,
      stream: 'leader',
      background: '#e2e2e2',
      accent: '#5525e5',
      text: '#000000',
      textHover: '#ffffff',
    },
    {
      id: 1,
      stream: 'people',
      background: '#e3e3e3',
      accent: '#5525e5',
      text: '#000000',
      textHover: '#ffffff',
    },
    {
      id: 2,
      stream: 'adventurer',
      background: '#e4e4e4',
      accent: '#5525e5',
      text: '#000000',
      textHover: '#ffffff',
    },
    {
      id: 3,
      stream: 'creative',
      background: '#e5e5e5',
      accent: '#c3d82e',
      text: '#000000',
      textHover: '#000000',
    },
    {
      id: 4,
      stream: 'maker',
      background: '#e6e6e6',
      accent: '#c3d82e',
      text: '#000000',
      textHover: '#000000',
    },
    {
      id: 5,
      stream: 'analyst',
      background: '#e7e7e7',
      accent: '#e52592',
      text: '#000000',
      textHover: '#ffffff',
    },
    {
      id: 6,
      stream: 'innovator',
      background: '#e8e8e8',
      accent: '#e52592',
      text: '#000000',
      textHover: '#ffffff',
    },
  ],
} as Themes;

// Intro Theme
const intro = {
  layout: 'image-right',
  shape: {
    id: 2,
    type: 'tri',
    path: '/images/shapes/shape_triangle.svg',
  },
  portrait: {
    id: 4,
    path: '/images/portraits/portrait-6__3.webp',
  },
  lockup: {
    id: 0,
    type: 'circle',
    path: '/images/lockups/lockup_circle_3.svg',
    locations: [
      {
        x: -2,
        y: 63,
        rotation: -5,
        scale: 1,
      },
      {
        x: -4,
        y: 23,
        rotation: -12,
        scale: 1,
      },
      {
        x: 15,
        y: -1,
        rotation: -15,
        scale: 1,
      },
      {
        x: 46,
        y: -10,
        rotation: 0,
        scale: 1,
      },
      {
        x: 80,
        y: 9,
        rotation: -20,
        scale: 1,
      },
      {
        x: 91,
        y: 37,
        rotation: 20,
        scale: 1,
      },
      {
        x: 84,
        y: 60,
        rotation: 15,
        scale: 1,
      }
    ],
  },
  color: {
    id: 1,
    stream: 'people',
    background: '#e1e1e1',
    accent: '#5525e5',
    text: '#000000',
    textHover: '#ffffff',
  },
  icons: [
    {
      id: 11,
      stream: 'adventurer',
      path: '/images/icons/icon_adventurer_1.svg',
    },
    {
      id: 21,
      stream: 'analyst',
      path: '/images/icons/icon_analyst_1.svg',
    },
    {
      id: 16,
      stream: 'creative',
      path: '/images/icons/icon_creative_1.svg',
    },
    {
      id: 8,
      stream: 'leader',
      path: '/images/icons/icon_leader_3.svg',
    },
    {
      id: 31,
      stream: 'maker',
      path: '/images/icons/icon_maker_1.svg',
    },
    {
      id: 26,
      stream: 'innovator',
      path: '/images/icons/icon_innovator_1.svg',
    },
    {
      id: 37,
      stream: 'people',
      path: '/images/icons/icon_people_2.svg',
    },
  ],
  accentLayout: {
    id: 0,
    locations: [
      {
        x: -4,
        y: -7,
        rotation: 80,
        scale: 1.1,
      },
      {
        x: 65,
        y: 20,
        rotation: -140,
        scale: 1,
      },
      {
        x: 5,
        y: 80,
        rotation: 0,
        scale: 1,
      },
      {
        x: 15,
        y: 15,
        rotation: 20,
        scale: 1.1,
      },
    ]
  },
  accents: [
    {
      id: 0,
      path: '/images/accents/accent-11.svg',
    },
    {
      id: 1,
      path: '/images/accents/accent-12.svg',
    },
    {
      id: 2,
      path: '/images/accents/accent-13.svg',
    },
    {
      id: 3,
      path: '/images/accents/accent-2.svg',
    },
  ]
} as Theme;

// Stream Themes
const adventurer = {
  shape: {
    path: '/images/shapes/shape_triangle.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-8__3.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_tri_2.svg',
    locations: [
      {
        x: 66,
        y: 3,
        rotation: 60,
        scale: 1,
      },
      {
        x: 74,
        y: 26,
        rotation: 25,
        scale: 1,
      },
      {
        x: 6,
        y: 21,
        rotation: -10,
        scale: 1,
      },
    ]
  },
  icons: [
    {
      path: '/images/icons/icon_adventurer_4.svg',
    },
    {
      path: '/images/icons/icon_adventurer_5.svg',
    },
    {
      path: '/images/icons/icon_adventurer_3.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 7,
        y: 32,
        rotation: 0,
        scale: 0.75,
      },
      {
        x: 27,
        y: 3,
        rotation: 0,
        scale: 0.9,
      },
      {
        x: 76,
        y: 32,
        rotation: 0,
        scale: 0.75,
      },
    ]
  },
  accents: [
    {
      path: '/images/accents/accent-7.svg',
    },
    {
      path: '/images/accents/accent-5.svg',
    },
    {
      path: '/images/accents/accent-8.svg',
    },
  ]
}

const leader = {
  shape: {
    path: '/images/shapes/shape_square.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-4__1.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_circle_4.svg',
    locations: [
      {
        x: 8,
        y: 58,
        rotation: -10,
        scale: 0.9,
      },
      {
        x: 52,
        y: 0,
        rotation: 27,
        scale: 0.9,
      },
      {
        x: 82,
        y: 56,
        rotation: 15,
        scale: 1,
      }
    ],
  },
  icons: [
    {
      path: '/images/icons/icon_leader_1.svg',
    },
    {
      path: '/images/icons/icon_leader_5.svg',
    },
    {
      path: '/images/icons/icon_leader_2.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-13.svg',
    },
    {
      path: '/images/accents/accent-4.svg',
    },
    {
      path: '/images/accents/accent-2.svg',
    },
    {
      path: '/images/accents/accent-12.svg',
    },
    {
      path: '/images/accents/accent-14.svg',
    },
    {
      path: '/images/accents/accent-15.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 20,
        y: 80,
        rotation: 0,
        scale: 0.9,
      },
      {
        x: 12,
        y: 4,
        rotation: 20,
        scale: 0.7,
      },
      {
        x: 24,
        y: 19,
        rotation: 5,
        scale: 0.85,
      },
      {
        x: 67,
        y: 12,
        rotation: -125,
        scale: 0.8,
      },
    ]
  },
}

const people = {
  shape: {
    path: '/images/shapes/shape_circle.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-7__1.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_square_2.svg',
    locations: [
      {
        x: 2,
        y: 25,
        rotation: -10,
        scale: 1,
      },
      {
        x: 27,
        y: 1,
        rotation: 18,
        scale: 1,
      },
      {
        x: 83,
        y: 18,
        rotation: 15,
        scale: 1,
      },
    ],
  },
  icons: [
    {
      path: '/images/icons/icon_people_2.svg',
    },
    {
      path: '/images/icons/icon_people_1.svg',
    },
    {
      path: '/images/icons/icon_people_3.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-1.svg',
    },
    {
      path: '/images/accents/accent-2.svg',
    },
    {
      path: '/images/accents/accent-10.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 18,
        y: 33,
        rotation: 0,
        scale: 1,
      },
      {
        x: 55,
        y: 4,
        rotation: 125,
        scale: 0.75,
      },
      {
        x: 66,
        y: 40,
        rotation: 45,
        scale: 1,
      },
    ]
  },
}

const analyst = {
  shape: {
    path: '/images/shapes/shape_triangle.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-1__2.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_square_1.svg',
    locations: [
      {
        x: 3,
        y: 17,
        rotation: -6,
        scale: 0.95,
      },
      {
        x: 55,
        y: 1,
        rotation: 7,
        scale: 1,
      },
      {
        x: 83,
        y: 26,
        rotation: 11,
        scale: 1,
      },
    ],
  },
  icons: [
    {
      path: '/images/icons/icon_analyst_2.svg',
    },
    {
      path: '/images/icons/icon_analyst_4.svg',
    },
    {
      path: '/images/icons/icon_analyst_5.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-4.svg',
    },
    {
      path: '/images/accents/accent-9.svg',
    },
    {
      path: '/images/accents/accent-8.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: -4,
        y: -10,
        rotation: 0,
        scale: 0.65,
      },
      {
        x: 23,
        y: 18,
        rotation: 10,
        scale: 1,
      },
      {
        x: 58,
        y: 18,
        rotation: 45,
        scale: 0.8,
      },
    ]
  },
}

const innovator = {
  shape: {
    path: '/images/shapes/shape_circle.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-5__1.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_tri_1.svg',
    locations: [
      {
        x: 8,
        y: 26,
        rotation: 20,
        scale: 1,
      },
      {
        x: 17,
        y: 2,
        rotation: 4,
        scale: 1,
      },
      {
        x: 78,
        y: 20,
        rotation: 16,
        scale: 1,
      },
    ]
  },
  icons: [
    {
      path: '/images/icons/icon_innovator_1.svg',
    },
    {
      path: '/images/icons/icon_innovator_2.svg',
    },
    {
      path: '/images/icons/icon_innovator_3.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-3.svg',
    },
    {
      path: '/images/accents/accent-6.svg',
    },
    {
      path: '/images/accents/accent-10.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 12,
        y: 45,
        rotation: 0,
        scale: 1,
      },
      {
        x: 60,
        y: -12,
        rotation: -10,
        scale: 0.9,
      },
      {
        x: 62,
        y: 13,
        rotation: 2,
        scale: 0.85,
      },
      {
        x: 85,
        y: -5,
        rotation: 0,
        scale: 1,
      },
    ]
  },
}

const creative = {
  shape: {
    path: '/images/shapes/shape_circle.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-2__2.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_circle_4.svg',
    locations: [
      {
        x: 8,
        y: 58,
        rotation: -10,
        scale: 0.9,
      },
      {
        x: 52,
        y: 0,
        rotation: 27,
        scale: 1,
      },
      {
        x: 82,
        y: 56,
        rotation: 15,
        scale: 1,
      }
    ],
  },
  icons: [
    {
      path: '/images/icons/icon_creative_2.svg',
    },
    {
      path: '/images/icons/icon_creative_1.svg',
    },
    {
      path: '/images/icons/icon_creative_4.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-13.svg',
    },
    {
      path: '/images/accents/accent-4.svg',
    },
    {
      path: '/images/accents/accent-11.svg',
    },
    {
      path: '/images/accents/accent-14.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 15,
        y: 80,
        rotation: 0,
        scale: 1,
      },
      {
        x: 14,
        y: 4,
        rotation: 20,
        scale: 0.9,
      },
      {
        x: 14,
        y: -5,
        rotation: 80,
        scale: 0.85,
      },
      {
        x: 85,
        y: -5,
        rotation: 0,
        scale: 1,
      },
    ]
  },
}

const maker = {
  shape: {
    path: '/images/shapes/shape_square.svg',
  },
  portrait: {
    path: '/images/portraits/portrait-3__1.webp',
  },
  lockup: {
    path: '/images/lockups/lockup_circle_1.svg',
    locations: [
      {
        x: 2,
        y: 40,
        rotation: -15,
        scale: 1,
      },
      {
        x: 22,
        y: 4,
        rotation: 25,
        scale: 1,
      },
      {
        x: 76,
        y: 20,
        rotation: 10,
        scale: 1.05,
      }
    ],
  },
  icons: [
    {
      path: '/images/icons/icon_maker_1.svg',
    },
    {
      path: '/images/icons/icon_maker_2.svg',
    },
    {
      path: '/images/icons/icon_maker_3.svg',
    },
  ],
  accents: [
    {
      path: '/images/accents/accent-13.svg',
    },
    {
      path: '/images/accents/accent-10.svg',
    },
    {
      path: '/images/accents/accent-1.svg',
    },
    {
      path: '/images/accents/accent-3.svg',
    },
  ],
  accentLayout: {
    locations: [
      {
        x: 15,
        y: 80,
        rotation: 0,
        scale: 1,
      },
      {
        x: 8,
        y: -2,
        rotation: 0,
        scale: 0.85,
      },
      {
        x: 30,
        y: 20,
        rotation: 0,
        scale: 1,
      },
      {
        x: 68,
        y: 40,
        rotation: -6,
        scale: 0.9,
      },
    ]
  },
}

export const streamThemes = {
  intro,
  adventurer,
  leader,
  people,
  analyst,
  innovator,
  creative,
  maker
}

interface ThemeColors {
  id: number;
  stream: string;
  hue: number;
  background: string;
  accent: string;
  text: string;
  textHover: string;
}

interface ThemeImage {
  id: number;
  stream?: string;
  type?: string,
  path: string;
}

interface ThemeLocation {
  x: number;
  y: number;
  rotation: number;
  scale: number;
}

interface ThemeLockup {
  id: number;
  type?: string,
  path: string;
  locations: ThemeLocation[];
}

export interface Theme {
  layout: string;
  icons: ThemeImage[];
  accents: ThemeImage[];
  accentLayout: {
    id: number;
    locations: ThemeLocation[];
  };
  shape: ThemeImage;
  lockup: ThemeLockup;
  portrait: ThemeImage;
  color: ThemeColors;
}
export interface Themes {
  layouts: string[];
  icons: ThemeImage[];
  accents: ThemeImage[];
  accentLayouts: {
    id: number;
    locations: ThemeLocation[];
  }[];
  shapes: ThemeImage[];
  lockups: ThemeLockup[];
  portraits: ThemeImage[];
  colors: ThemeColors[];
}

export default themes;
import React, { ReactNode, useContext } from 'react'
import { SketchInputStyled } from './SketchInput.styles';
import { ReactSVG } from 'react-svg'
import { SketchButtonProps } from '../SketchButton';
import { GlobalContext } from '../../App';

interface SketchInputProps extends Omit<SketchButtonProps, 'text'> {
  placeholder: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  children?: ReactNode;
}

const SketchInput = ({ label, type = 'button', outlineColor = '#fff', textColor = '#fff', textHoverColor, backgroundColor = '#5824E1', placeholder = '', setValue, children }: SketchInputProps) => {
  if (!textHoverColor) textHoverColor = textColor;


  const { assetUrl } = useContext(GlobalContext);

  return (
    <SketchInputStyled
      className="sketch__input"
      type={type}
      textColor={textColor}
      textHoverColor={textHoverColor}
    >
      <ReactSVG
        className="sketch-button__background"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${backgroundColor}`)
        }}
        src={assetUrl + '/images/buttons/question-button__background--small.svg'}
      />
      {children || <input
        placeholder={placeholder}
        aria-label={label}
        onChange={(ev) => { if (setValue) setValue(ev?.target?.value) }}
      />}
      <ReactSVG
        className="sketch-button__outline"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${outlineColor}`)
        }}
        src={assetUrl + '/images/buttons/question-button__outline--small.svg'}
      />
    </SketchInputStyled>
  )
}

export default SketchInput;

import styled from 'styled-components';

export const AnimatedGridStyled = styled.div`
  position: absolute;
  z-index: 0;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;

  canvas{
    position: absolute;
    z-index: 0;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    transform: translate(calc(var(--parallax-offset-x)), calc(var(--parallax-offset-y))); 
  }
`

export default AnimatedGridStyled;
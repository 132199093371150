import styled from 'styled-components';
import { SketchButtonStyledProps } from '../SketchButton/SketchButton.styles';

export const SketchButtonArrowStyled = styled.button<SketchButtonStyledProps>`
  all: unset;
  position: relative ;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1.3rem;
  font-weight: bold;
  justify-items: center;
  justify-self: left;
  container: button;
  container-type: normal;

  .sketch-button__outline {
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 5rem 2rem 2rem;
    box-sizing: border-box;
    font-size: 1.3rem;
    line-height: 1.1;
  }

  .sketch-button__background {
    transform: skew(16deg, -1deg) scaleY(0.9);
    opacity: 0;
    /* clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    transition-property: clip-path;
    transition-timing-function: cubic-bezier(0.55, 0.57, 0, 1.03);
    transition-duration: 250ms; */
  }

  :hover, :active {
    .sketch-button__background {
      opacity: 1;
      /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); */
    }
    .sketch-button__outline {
      transform: skew(4deg,-1deg) rotate3d(1,0,0,182deg) scaleY(0.95);

      svg {
        fill: #000 !important;
      }
    }

    span {
      color: ${props => props?.textHoverColor || '#fff'} !important;
    }
  }

  :focus-visible {
    outline: 2px dashed #000;
    border-radius: 4px;
    outline-offset: 3px;
  }
`
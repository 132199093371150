import React, { useContext } from 'react'
import { SketchImageStyled } from './SketchImage.styles';
import { ReactSVG } from 'react-svg'
import { GlobalContext } from '../../App';

interface SketchImageProps {
  url: string;
  text: string;
  image: {
    url: string;
    alt: string;   
  }
  outlineColor?: string;
}

const SketchImage = ({ url, image, text, outlineColor = '#fff' }: SketchImageProps) => {

  const { assetUrl } = useContext(GlobalContext);

  // Randomly flip shapes, first flip outline and reverse flip for image container. Then apply again to image to make upright
  const randomFlipAxis = (): number => Math.random() > 0.5 ? 1 : -1;

  const outlineFlipX = randomFlipAxis();
  const outlineFlipY = randomFlipAxis();

  const outlineFlip = `${outlineFlipX} ${outlineFlipY}`;
  const imageFlip = `${-outlineFlipX} ${-outlineFlipY}`;

  return (
    <>
      <SketchImageStyled
        href={url}
        target="_blank"
      >
        <div className="sketch-image__lockup">
          <svg xmlns="http://www.w3.org/2000/svg" height="0" width="0" viewBox="0 0 308 175.02">
            <defs>
              <clipPath
                id="maskpath"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.003246753246753, 0.005713632727688)"
              >
                <path d='M307.959,148.931,299.4,26.79c-.525-7.483-4.843-13.264-10.113-13.539L34.247.007c-3.269-.17-6.178,2.944-6.977,7.47L.25,160.624c-1.317,7.461,2.741,14.644,8.134,14.4l291.758-13.366c4.7-.215,8.285-6.055,7.817-12.724Z'/>
              </clipPath>
            </defs>
          </svg>
          <ReactSVG
            className="sketch-image__outline"
            beforeInjection={(svg) => {
              svg.setAttribute('style', `fill: ${outlineColor}; scale: ${outlineFlip}`)
            }}
            src={assetUrl + '/images/buttons/image__outline.svg'}
          />
          <div
            className="sketch-image__picture"
            style={{ scale: imageFlip }}
          >
            <img
              style={{ scale: imageFlip }}
              src={image?.url}
              alt={image?.alt || text}
            />
          </div>
        </div>
        <p>{text}</p>
      </SketchImageStyled>
    </>
  )
}

export default SketchImage;

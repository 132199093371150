import styled from 'styled-components';


export const SketchImageStyled = styled.a`
  all: unset;
  position: relative ;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1.3rem;
  font-weight: bold;
  justify-items: center;
  justify-self: left;
  width: 100%;

  .sketch-image__lockup {
    position: relative;

    >svg {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    .sketch-image__outline {
      position: relative;
      width: 100%;
      height: auto;

      >div {
        width: 100%;
        height: auto;

        >svg {
          width: 100%;
          height: auto;
        }
      }
    }
      
    .sketch-image__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: url(#maskpath);

      img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  >p {
    font-weight: 500;
  }

  :hover {
    .sketch-image__outline {
      transform: skew(357deg,5deg) scale(-1,-1) translate(10px,10px);
    }
    .sketch-image__picture {
      transform: rotate(3deg);
      img {
        transform: scale(1.05);
      }
    }

    >p {
      text-decoration: underline;
    }
  }
`
import styled from 'styled-components';
import { media } from './../../../lib/mediaQueries';

export const QuestionStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  margin: auto;
  padding: 0 1.5rem;
  width: 100%;
  min-height: 100vh;
  max-width: 1600px;
  align-items: end;

  ${media.greaterThan('md')`
    padding: 0 3rem;
    align-items: unset;
  `}

  .question__content {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    text-align: center;
  }

  .question__image {
    order: 1;
  }

  ${media.greaterThan('md')`
    grid-template-columns: 50% minmax(0, 50%);
    grid-template-rows: 1fr;
    column-gap: 4rem;

    && .question__image {
      order: -1;
      display: flex;
      position: sticky;
      top: 0;
      height: 100vh;
    }
  `}
    
  &.question--slider {
    align-self: center;
    justify-items: center;
    grid-template-columns: 1fr;
    max-width: 90%;
  }

  &.question--left { 
    ${media.greaterThan('md')`
      .question__image {
        order: 1;
      }
    `}

    ${media.greaterThan('lg')`
      .question__content {
        margin-right: auto;
        margin-left: auto;
      }
        
    `}
  }

  .question__content {
    align-items: center;
    width: 100%;
    min-height: 60%;
    padding: 4rem 0;
    justify-content: space-evenly;
    transform: translate(calc(var(--parallax-offset-x) * 2), calc(var(--parallax-offset-y) * 2));
    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: 500ms;
    
    h1 {
      margin: 0 0 4rem 0;
      transition-property: transform -webkit-transform;
      transition-timing-function: ease-out;
      transition-duration: 350ms;
      
      ${media.greaterThan('md')`
        padding: 1rem;
      `}

      span {
        display: inline-block;
        position: relative;
        z-index: 0;
      }
    }

    div.fade-enter & {
      opacity: 0;
     /*  h1 {
        transform: translate(-50%, 0%);
      } */
    }

    div.fade-enter div.question--right & {
     /*  h1 {
        transform: translate(50%, 0%) !important;
      } */
    }

    div.fade-enter div.question--slider & {
      /* h1 {
        transform: translate(0%, 50%) !important;
      } */
    }
    
    div.fade-enter-active & {
      opacity: 1;
      transition-duration: 200ms;
    /*   h1 {
        transform: translate(0%, 0%);
        div.question--slider & {
          transform: translate(0%, 0%);
        }
      } */
    }

    div.fade-exit & {
      opacity: 1;
    /*   h1 {
        transform: translate(0%, 0%);
        div.question--slider & {
          transform: translate(0%, 0%);
        }
      } */
    }

    div.fade-exit-active & {
      opacity: 0;
      transition-duration: 200ms;
  /*     h1 {
        transform: translate(0%, 0%);
        div.question--slider & {
          transform: translate(0%, 0%);
        }
      } */
    }
  }

  &.question--right {
    ${media.greaterThan('lg')`
      .question__content {
        margin-right: auto;
        margin-left: auto;
      }
    `}
  }

  &.question--no-image {
    justify-items: center;
    grid-template-columns: 1fr;

    .question__content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto minmax(auto, 1fr);
      row-gap: 2rem;
      align-items: center;
      justify-items: center;
      max-width: 96%;

      ${media.greaterThan('md')`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
      `}

      > div {
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
      }
    }
  }
`

export const AnswersStyled = styled.div`
  display: grid;
  grid-gap: var(--gap);
  grid-row-gap: var(--gap);
  grid-template-columns: 1fr;
  width: 100%;

  >button {
    margin: 0 auto;
  }

  ${media.greaterThan('lg')`
    grid-template-columns: 1fr 1fr 1fr 1fr;

    >button {
      margin: 0 auto;
      grid-column: span 2;
    }

    >button:nth-last-child(1):nth-child(odd) {
      grid-column: 2 / span 2;

      div.question--no-image & {
        grid-column: span 1;
      }
    }
  `}

  div.question--answers-medium &, div.question--answers-large & {
    width: 80%;
  }

  &.answers--left {
    grid-template-columns: 1fr;
    &&>button {
      margin: 0;
      grid-column: unset;
    }
  }

  div.question--no-image && {
    width: 100%;

    >button {
      margin: 0 auto;
      grid-column: 1 / span 3 !important;
    }
  }
`


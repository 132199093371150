import styled from 'styled-components';

interface SketchCheckboxStyledProps {
  textColor: string;
  textHoverColor: string;
}

export const SketchCheckboxStyled = styled.div<SketchCheckboxStyledProps>`
  all: unset;
  position: relative ;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  line-height: 1.3rem;
  font-weight: bold;
  justify-items: center;
  justify-self: left;
  width: 3rem;
  height: 3rem;

  .sketch-button__outline {
    position: absolute;
    top: 0;
    left: 0;
  }

  input {
    display: none;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
`
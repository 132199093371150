import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';
import anime from 'animejs';

const AnimatedTextStyled = styled.span`

`

interface AnimatedTextProps {
  text: string;
}

const AnimatedText = ({ text }: AnimatedTextProps) => {
  const wordArr = text?.split(' ') || [];
  const animationRef = useRef(null);

  useEffect(() => {
    if (!animationRef?.current) {
      animationRef.current = anime({
        targets: ".animated-text__main > span",
        opacity: [0, 1],
        translateY: ['25%', '0%'],
        duration: 150,
        delay: anime.stagger(40),
        easing: "easeInQuad"
      });
    }
  }, []);

  return (
    <AnimatedTextStyled className="animated-text__main">
      {!!wordArr.length && wordArr.map((word, i) => (
        <span key={i}>{word}&nbsp;</span>
      ))}
    </AnimatedTextStyled>
  )
}

export default AnimatedText;

import styled from 'styled-components';
import { fluidProperty } from 'lib/FluidProperty';

const AppStyled = styled.div`

  @import url("https://use.typekit.net/yuc0pzz.css");

  --font-fallback: Helvetica, Arial, sans-serif;
  --font-title: 'bd-supper', var(--font-fallback);
  --font-body: 'proxima-nova', var(--font-fallback);
  
  ${fluidProperty({ property: '--gap', lowestSize: '0.75em', highestSize: '1.5em' })}
  
  transition: 0.6s linear all;
  min-height: 100%;
  font-family: var(--font-body);

  .container {
    position: relative;
    z-index: 1;
    min-height: 100%;
    /* max-width: 1400px; */
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-title);
    font-weight: 400;
  }

  h1 {
    ${fluidProperty({ property: 'font-size', lowestSize: '3em', highestSize: '4em' })}
    line-height: 1;
    color: white;

  }

  .stage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  h1,
  h2,
  h3,
  p,
  a {
    color: inherit;
  }


  a:focus {
    border-radius: 2px;
    outline: 2px dashed #000;
    outline-offset: 3px;
  }

  .scores {
    position: absolute;
    bottom: 0;
    
    li {
      display: block;
      color: white;
      font-size: 10px;
    }
  }

  button {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

export default AppStyled;
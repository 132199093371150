import styled from 'styled-components';

export const ImageDeviceAccentsStyled = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;

  --accent-parralax-offset: 0;
  --accent-parralax-factor: calc(1.5 - var(--accent-parralax-offset));
  --accent-rotation-scale: rotate(0deg) scale(1);

  .image-device-accents__accent {
    position: absolute;
    width: 17%;
    transform: var(--accent-rotation-scale);
    transition-delay: 150ms;
  }

  div.fade-enter & .image-device-accents__accent {
    opacity: 0;
    transition-property: opacity transform -webkit-transform;
    transition-duration: 150ms;
    transform: var(--accent-rotation-scale) translateY(100%);
  }

  div.fade-enter-active & .image-device-accents__accent {
    opacity: 1;
    transition-property: opacity transform -webkit-transform;
    transform: var(--accent-rotation-scale) translateY(0%);
  }

  div.fade-exit & .image-device-accents__accent {
    opacity: 1;
    transition-property: opacity transform -webkit-transform;
    transform: var(--accent-rotation-scale) translateY(0%);
  }

  div.fade-exit-active & .image-device-accents__accent {
    opacity: 0;
    transition-property: opacity transform -webkit-transform;
    transition-duration: 150ms;
    transform: var(--accent-rotation-scale) translateY(100%);
  }

`
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useMemo, useState } from 'react';
import { QuestionStyled, AnswersStyled } from './Question.styles';
import { useParams } from 'react-router-dom';
import Slider from '../../atoms/Slider';
import classNames from 'classnames';
import ImageDevice from '../../molecules/ImageDevice';
import { Theme } from 'theme/theme';
import SketchButton from '../../atoms/SketchButton';
import SketchButtonArrow from '../../atoms/SketchButtonArrow';
import AnimatedText from '../../atoms/AnimatedText';
import SketchButtonQuestion from '../../atoms/SketchButtonQuestion';
import { debounce } from 'lib/tools';

export interface QuestionItemsProps {
  id: number;
  title: string;
  answers: AnswerProps[];
  isStarter?: Boolean;
  isFinal?: Boolean;
  stream?: "adventurer" | "analyst" | "creative" | "innovator" | "leader" | "maker" | "people";
  nextId?: number;
  type: 'standard' | 'range' | 'blah';
  bg?: string;
}

export interface AnswerProps {
  text: string;
  score?: {
    adventurer: number;
    analyst: number;
    creative: number;
    innovator: number;
    leader: number;
    maker: number;
    peoplePerson: number;
  }
  linkedId?: number;
}

interface QuestionProps {
  questions?: QuestionItemsProps[];
  answerQuestion: Function;
  theme: Theme;
}

export const Question = ({ questions, answerQuestion, theme }: QuestionProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const { qid } = useParams();

  const question = useMemo(() => {
    return questions[qid];
  }, [qid]);

  if (!question) {
    return <></>;
  }

  const [slideValue, setSlideValue] = useState(3);

  const { title = '', answers = [], type, isFinal } = question || {};

  answers?.sort((a, b) => {
    return a?.id > b?.id ? 1 : -1;
  });

  const answersLength = [...answers]?.sort((a, b) => a?.text?.length > b?.text?.length ? -1 : 1)?.[0]?.text?.length;
  const answersSize = answersLength > 60 ? 'large' : answersLength > 35 ? 'medium' : 'small';


  const classes = classNames({
    'question': true,
    'question--slider': type === 'range',
    'question--standard': type === 'standard',
    'question--left': type === 'standard' && theme?.layout === 'image-left',
    'question--right': type === 'standard' && theme?.layout === 'image-right',
    'question--no-image': type === 'standard' && theme?.layout === 'no-image',
    [`question--answers-${answersSize}`]: true,
  });

  // Check for mobile breakpoint to switch out SketchButtonArrows
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  
  const debouncedHandleResize = debounce(handleResize, 50)

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', debouncedHandleResize);

    () => window.removeEventListener('resize', debouncedHandleResize);
  }, [])

  return (
    <QuestionStyled className={classes}>
      {!!question && <>
        <div className='question__content'>
          <h1 style={{ color: theme?.color?.text || '#000' }}><AnimatedText text={title} /></h1>
          {type == 'range' ? (
            <>
              <Slider
                answers={answers}
                setSlideValue={setSlideValue}
                baseColor={theme?.color?.text}
                handleColor={theme?.color?.accent}
              />
              <AnswersStyled className="answers--left">
                <SketchButton
                  text={'Next'}
                  label={'Next Question'}
                  textColor={theme?.color?.text}
                  textHoverColor={theme?.color?.textHover || '#fff'}
                  outlineColor={theme?.color?.accent}
                  backgroundColor={theme?.color?.accent}
                  clickHandler={() => answerQuestion(qid, answers[slideValue - 1], isFinal)}
                />
              </AnswersStyled>
            </>
          ) : type === 'standard' && theme?.layout === 'no-image' ? (
            <AnswersStyled>
              {isMobile ? (
                <>
                  {answers?.map((answer, i) => (
                    <SketchButtonQuestion
                      key={'answer-' + i}
                      text={answer?.text}
                      label={answer?.text}
                      size={answersSize}
                      textColor={theme?.color?.text}
                      textHoverColor={theme?.color?.textHover || '#fff'}
                      outlineColor={theme?.color?.accent}
                      backgroundColor={theme?.color?.accent}
                      clickHandler={() => answerQuestion(qid, answer, isFinal)}
                    />
                  ))}
                </>
              ) : (
                <>
                  {answers?.map((answer, i) => (
                    <SketchButtonArrow
                      key={'answer-' + i}
                      text={answer?.text}
                      label={answer?.text}
                      textColor={theme?.color?.text}
                      textHoverColor={theme?.color?.textHover || '#fff'}
                      outlineColor={theme?.color?.accent}
                      backgroundColor={theme?.color?.accent}
                      clickHandler={() => answerQuestion(qid, answer, isFinal)}
                    />
                  ))}
                </>
              )}
            </AnswersStyled>
          ) : (
            <AnswersStyled>
              {answers?.map((answer, i) => (
                <SketchButtonQuestion
                  key={'answer-' + i}
                  text={answer?.text}
                  label={answer?.text}
                  size={answersSize}
                  textColor={theme?.color?.text}
                  textHoverColor={theme?.color?.textHover || '#fff'}
                  outlineColor={theme?.color?.accent}
                  backgroundColor={theme?.color?.accent}
                  clickHandler={() => answerQuestion(qid, answer, isFinal)}
                />
              ))}
            </AnswersStyled>
          )}
        </div>
        {type !== 'range' && theme.layout !== 'no-image' &&
          <div className='question__image'>
            <ImageDevice theme={theme} />
          </div>
        }
      </>}
    </QuestionStyled>
  )
}

export default Question;
import { GlobalContext } from '../../App';
import React, { useContext } from 'react'
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

const TraitStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  .trait__icon {
    width: 4rem;
    height: 4rem;
  }

  p {
    font-weight: 600;
  }
`

interface TraitProps {
  color: string;
  icon: string;
  text: string;
}

const Trait = ({ color = '#fff', icon, text }: TraitProps) => {

  const { assetUrl } = useContext(GlobalContext);

  return (
    <TraitStyled style={{ color }}>
      <ReactSVG
        className="trait__icon"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${color}`)
        }}
        src={assetUrl + `/images/icons/${icon}.svg`}
      />
      <p>{text}</p>
    </TraitStyled>
  )
}

export default Trait

import React, { Dispatch, SetStateAction } from 'react';
import { QuestionItemsProps } from '../Question';
import { useNavigate } from 'react-router-dom';
import { DetailsStyled } from './Details.styles';
import { Theme } from 'theme/theme';
import Slider from '../../atoms/Slider';
import SketchInput from '../../atoms/SketchInput';
import SketchButtonQuestion from '../../atoms/SketchButtonQuestion';
import { useForm } from 'react-hook-form';
import { UserDataModel } from '../../App';

interface DetailsProps {
  questions?: QuestionItemsProps;
  theme: Theme;
  userData: UserDataModel;
  setUserData: Dispatch<SetStateAction<UserDataModel>>;
}

const answers = [
  {
    text: 'Year 9 student',
    value: 'Year 9'
  },
  {
    text: 'Year 10 student',
    value: 'Year 10'
  },
  {
    text: 'Year 11 student',
    value: 'Year 11'
  },
  {
    text: 'Year 12 student',
    value: 'Year 12'
  },
  {
    text: 'High school graduate',
    value: 'School leaver'
  },
  {
    text: 'University or TAFE student',
    value: 'Mature age'
  },
  {
    text: 'Working or career break',
    value: 'Mature age'
  }
]

export const Details = ({questions, theme, userData, setUserData}: DetailsProps) => {
  // find all isStarter q's
  const starters = Object.keys(questions).filter((qid) => questions[qid].isStarter);

  //const starters = ['60', '61', '62', '63', '64', '65', '66']; // final questions
  //const starters = ['22', '37', '25']; // range slider quesitons

  // set one of them as the starter question randomly
  const introQuestion = (questions[starters[Math.floor(Math.random()*starters.length)]]);
  
  const navigate = useNavigate();

  
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    setUserData({
      ...userData,
      'FirstName': data?.name
    })
    
    if (data?.name) navigate('/question/' + introQuestion.id, {replace: true});
  }
  
  return (
    <DetailsStyled color={theme?.color?.text || '#fff'} colorTextHover={theme?.color?.textHover || '#fff'}  colorAccent={theme?.color?.accent || '#000'} onSubmit={handleSubmit(onSubmit)}>
      <h1>It all starts with you</h1>
      <div className='details__inputs'>
        <div className='details__input'>
          <SketchInput
            label="First name"
            placeholder="First name"
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
          >
            <input
              placeholder="First name"
              aria-label="First name"
              {...register("name", {required: true, maxLength: 80})}
            />
          </SketchInput>
          {errors.name && errors.name.type === "required" && <span>Required</span>}
        </div>
        {/* <div className='details__input'>
          <SketchInput
            label="Your Email"
            placeholder="Your Email"
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
          >
            <input
              placeholder="Your Email"
              aria-label="Your Email"
              {...register("email", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i})} 
            />
          </SketchInput>
          {errors.email && errors.email.type === "required" && <span>Required</span>}
          {errors.email && errors.email.type === "pattern" && <span>Please add a valid email</span> }
        </div> */}
        <h2>What best describes your current level of education?</h2>
        <Slider
          answers={answers}
          setSlideValue={(value: number) => setUserData({
            ...userData,
            'levelofeducation': answers[value - 1].value,
          })}
          baseColor={theme?.color?.text}
          handleColor={theme?.color?.accent}
        />
        <SketchButtonQuestion
          text={'I\'m ready to go'}
          label={'I\'m ready to go'}
          type="submit"
          textColor={theme?.color?.text}
          textHoverColor={theme?.color?.textHover || '#fff'}
          outlineColor={theme?.color?.text}
          backgroundColor={theme?.color?.accent}
        />
      </div>
    </DetailsStyled>
  )
}

export default Details;
import styled from 'styled-components';
import { media } from '../../../lib/mediaQueries';

interface SignupStyledProps {
    color?: string;
    colorAccent?: string;
    colorTextHover?: string;
}

export const SignupStyled = styled.form<SignupStyledProps>`
  width: 100%;
  padding: 3rem 2rem;
  margin: auto;
  transform: translate(calc(var(--parallax-offset-x) * 2), calc(var(--parallax-offset-y) * 2));

  h1, h2, h3, h4, h5, h6, p, a, >div {
    color: ${props => props?.color || '#fff'};
    transition-property: color;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
  }
  
  ${media.greaterThan('md')`
    max-width: 800px;
  `}

  h1 {
    text-align: center;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    text-align: center;
    margin: 0 !important;
  }

  .signup__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;

    .signup__input {
      display: flex;
      flex-direction: column;

      >span {
        font-weight: bold;
        padding: 0.5rem;
        background-color: ${props => props?.colorAccent || '#000'};
        color: ${props => props?.colorHoverText || '#fff'};
        width: max-content;
        border-radius: 4px;
        margin-top: 0.5rem;
      }

      >label {
        display: flex;
        align-items: center;
        max-width: 800px;
        column-gap: 0.5rem;
        cursor: pointer;
        font-size: 1.2rem;

        p {
          text-align: left;
        }
      }
    }

    .sketch__input {
      ${media.greaterThan('md')`
        max-width: 400px;
      `}
    }

    .resident__input {
      p {
        font-size: 1.2rem;
        margin-bottom: 1rem !important;
      }

      >span {
        font-weight: bold
      }
      
      div {
        display: flex;
        flex-direction: column;

        ${media.greaterThan('sm')`
          flex-direction: row;
        `}
      }
    }
  }

  .slider {
    margin-bottom: 4rem;
  }

  .rc-slider-mark-text {
    white-space: unset;
  }

  .sketch-button-question {
    max-width: 320px;
  }

  ${media.greaterThan('md')`
    width: 90%;
    max-width: 1600px;
  `}

  div.fade-enter & {
      opacity: 0;
  }

  div.fade-enter-active & {
      opacity: 1;
      transition: opacity 300ms;
  }

  div.fade-exit & {
      opacity: 1;
  }

  div.fade-exit-active & {
      opacity: 0;
      transition: opacity 300ms;
  }

`
import { fluidProperty } from 'lib/FluidProperty';
import styled from 'styled-components';
import { media } from './../../../lib/mediaQueries';


export const IntroStyled = styled.div`
  > div {
    .question__content {
      & {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        text-align: left !important;
      }

      ${media.greaterThan('md')`
      `}

      h1 {
        ${fluidProperty({ property: 'font-size', lowestSize: '4.6em', highestSize: '6em' })}
        margin: 0 0 2rem 0;
        padding: 0;
      }

      p {
        text-align: left;
        line-height: 1.5;
      }

      >button {
        margin: 2rem 0;
      }
    }
  }
`
import styled from 'styled-components';

export const ImageDeviceLockupStyled = styled.div`
  position: relative;

  .image-device-lockup__main {
    width: 100%;
    height: max-content;

    div.fade-enter & {
      opacity: 0;
    }

    div.fade-enter-active & {
      animation-name: drawin;
      animation-duration: 250ms;
      animation-delay: 150ms;
      animation-direction: normal;
      animation-fill-mode: both;
    }

    div.fade-exit & {
      opacity: 0;
    }

    div.fade-exit-active & {
      opacity: 0;
    }
  }

  --icon-rotation-scale: rotate(0deg) scale(1);

  .image-device-lockup__icon {
    position: absolute;
    width: 17%;
    transform: var(--icon-rotation-scale);

    transition-timing-function: ease-in;
    transition-duration: 600ms;

    >div {
      transform: rotate(calc(var(--parallax-rotation) * -5));
    }
  }

  div.fade-enter & .image-device-lockup__icon {
    opacity: 0;
    transition-property: opacity transform -webkit-transform;
    transition-duration: 150ms;
    transform: var(--icon-rotation-scale) translateY(100%);
  }

  div.fade-enter-active & .image-device-lockup__icon {
    opacity: 1;
    transition-property: opacity transform -webkit-transform;
    transform: var(--icon-rotation-scale) translateY(0%);
  }

  div.fade-exit & .image-device-lockup__icon {
    opacity: 1;
    transition-property: opacity transform -webkit-transform;
    transform: var(--icon-rotation-scale) translateY(0%);
  }

  div.fade-exit-active & .image-device-lockup__icon {
    opacity: 0;
    transition-property: opacity transform -webkit-transform;
    transition-duration: 150ms;
    transform: var(--icon-rotation-scale) translateY(100%);
  }

  @keyframes drawin {

    from {
      opacity: 0;
      clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%);
    }

    13% {
      clip-path: polygon(50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%);
    }
    
    26% {
      clip-path: polygon(89% 86%, 89% 86%, 89% 86%, 50% 100%, 50% 50%, 89% 86%, 89% 86%, 89% 86%, 89% 86%);
    }
    
    39% {
      clip-path: polygon(98% 47%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 98% 47%, 98% 47%, 98% 47%, 98% 47%);
    }
    
    52% {
      clip-path: polygon(73% 2%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 73% 2%, 73% 2%, 73% 2%, 73% 2%);
    }
    
    65% {
      clip-path: polygon(73% 2%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 25% 3%, 25% 3%, 25% 3%, 25% 3%);
    }

    78% {
      clip-path: polygon(73% 2%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 1% 48%, 1% 48%, 1% 48%, 25% 3%);
    }

    91% {
      clip-path: polygon(73% 2%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 14% 89%, 14% 89%, 1% 48%, 25% 3%);
    }

    to {
      opacity: 1;
      clip-path: polygon(73% 2%, 98% 47%, 89% 86%, 50% 100%, 50% 50%, 50% 100%, 14% 89%, 1% 48%, 25% 3%);
    }
  }
`
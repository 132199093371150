import React, { useContext } from 'react'
import { SketchButtonStyled } from './SketchButton.styles';
import { ReactSVG } from 'react-svg'
import { GlobalContext } from '../../App';

export interface SketchButtonProps {
  label?: string;
  text: string;
  type?: 'button' | 'submit' | 'reset';
  clickHandler?: VoidFunction;
  outlineColor?: string;
  textColor?: string;
  textHoverColor?: string;
  disabled?: boolean;
  backgroundColor: string;
}

const SketchButton = ({
    label,
    text,
    type = 'button',
    outlineColor = '#5824E1',
    textColor = '#000',
    textHoverColor = '#000',
    backgroundColor = '#5824E1',
    disabled,
    clickHandler
  }: SketchButtonProps) => {
  if (!textHoverColor) textHoverColor = textColor;

  const { assetUrl } = useContext(GlobalContext);

  return (
    <SketchButtonStyled
      aria-label={label || text}
      type={type}
      onClick={clickHandler}
      textHoverColor={textHoverColor}
      disabled={disabled}
    >
      <ReactSVG
        className="sketch-button__background"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${backgroundColor}`)
        }}
        src={assetUrl + '/images/buttons/button__background.svg'}
      />
      <span style={{ color: textColor }}>
        {text}
      </span>
      <ReactSVG
        className="sketch-button__outline"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${outlineColor}`)
        }}
        src={assetUrl + '/images/buttons/button__outline.svg'}
      />
    </SketchButtonStyled>
  )
}

export default SketchButton;

import Color from 'colorjs.io';
import * as dat from 'dat.gui';
import PerlinNoise from '../../../lib/PerlinNoise';
import anime from 'animejs';


export default class AnimatedGridView {
  nt = 0;
  noiseSpeed = 0.008;
  baseNoiseSpeed = this.noiseSpeed;
  noiseScale = 500;
  dotSize = 140;
  gap = 0;
  colorBase = '#fff';
  colorTarget = this.colorBase;
  lightnessRange = 3.5;
  baseLightnessRange = this.lightnessRange;
  shape = 0;

  constructor (canvas, w, h) {
    this.ctx = canvas.getContext("2d");
    this.w = this.ctx.canvas.width = w;
    this.h = this.ctx.canvas.height = h;

    // Init Dat GUI, any existing gui instance
    /* document.getElementById('dat-gui')?.remove();

    this.gui = new dat.GUI();
    this.gui.domElement.id = 'dat-gui'
    this.gui.add(this, 'noiseSpeed', 0.0, 0.02);
    this.gui.add(this, 'noiseScale', 100, 1000);
    this.gui.add(this, 'dotSize', 50, 500).step(1);
    this.gui.add(this, 'gap', 0, 10).step(1);
    this.gui.add(this, 'colorBase');
    this.gui.add(this, 'lightnessRange', 0, 100).step(1);
    this.gui.close(); */
 
    // for reals remove it
    //document.getElementById('dat-gui')?.remove();

    // Start Render
    this.render();
  }

  onWindowResize(width, height) {
    this.w = this.ctx.canvas.width = width;
    this.h = this.ctx.canvas.height = height;
  }

  updateColor(targetColor) {
    // Using color.js to properly interpolate between hues consistently
    let c1 = new Color(this.colorBase);
    let c2 = new Color(targetColor);
    
    let gradient = c1.range(c2, {
      space: "srgb", // interpolation space
      hue: "shorter",
      outputSpace: "srgb"
    });

    let props = {
      t: 0,
    }
    
    // Transitions between colours, flare squares using lightnessRange nad noiseScale
    anime({
      targets: props,
      t: 1,
      duration: 1300,
      easing: 'easeInOutQuad',
      update: () => {
        requestAnimationFrame(() => {
          // Sin transition time to allow for values increase until half way and return
          const sinTime = Math.sin(props.t * Math.PI) + 1; // Sin range starting from 1 raising to 2 at t0.5 and return to 1
          //const cosTime = Math.cos(props.t * 2 * Math.PI) / 10 + 0.9; // Cos range starting from 1 dropping to 0.8 at t0.5 and return to 1
          this.lightnessRange = this.baseLightnessRange * sinTime;
          this.noiseSpeed = this.baseNoiseSpeed * sinTime * 3;
          //this.noiseScale = tempNoiseScale * cosTime;

          // Color transition
          //console.log('t: ', props?.t, ' hsl: ', gradient(props?.t).hsl)
          /* const gradientPos = gradient(props?.t);
          this.colorBase = gradientPos.toString({precision: 3}); */
        })
      },
      complete: () => {
        requestAnimationFrame(() => {
          // Set transitions properties back to original values
          this.lightnessRange = this.baseLightnessRange;
          this.noiseSpeed = this.baseNoiseSpeed;
          //this.noiseScale = tempNoiseScale;
        })
      }
    });
    
  }


  // Canvas Start
  draw() {
    this.nt += this.noiseSpeed;
    for (let x = 0; x < this.w; x += this.dotSize + this.gap) {
      for (let y = 0; y < this.h; y += this.dotSize + this.gap) {
        const yn = PerlinNoise.perlin3(y / this.noiseScale, x / this.noiseScale, this.nt) * 20;
        const cn = this.lerp(this.lightnessRange, yn * this.lightnessRange, 0.2);

        /* const color = new Color(this.colorBase);
        color.to("hsl") */


        this.ctx.beginPath();
        this.ctx.fillStyle = "hsla(" + 0 + "," + 0 + "%," + (96 + cn) + "%," + 1 + ")";
        this.ctx.fillRect(x, y, this.dotSize, this.dotSize);
        this.ctx.closePath();
      }
    }
  }

  clear() {
    this.ctx.fillStyle = "rgba(255,255,255,1)";
    this.ctx.fillRect(0, 0, this.w, this.h);
  }

  lerp(x1, x2, n) {
    return (1 - n) * x1 + n * x2;
  }

  render() {
    this.clear();
    this.draw();
    requestAnimationFrame(this.render.bind(this));
  }
  // Canvas End
}

/* eslint-disable @typescript-eslint/ban-types */
import React, { Fragment, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { OutcomeStyled } from "./Outcome.styles";
import SketchButton from '../../atoms/SketchButton';
import { Theme } from "theme/theme";
import { Scores } from "../../App";
import { QuestionStyled } from "../Question/Question.styles";
import ImageDevice from "../ImageDevice";
import Trait from "../../atoms/Trait";
import SketchImage from "../../atoms/SketchImage";
interface OutcomeProps {
  updateTheme: Function;
  resetScores: Function;
  theme: Theme;
  streamThemes: any;
  outcomes: [
    {
      id: number;
      stream: string;
      content?: string;
    }
  ]
}

export const Outcome = ({ outcomes, updateTheme, resetScores, streamThemes, theme }: OutcomeProps) => {

  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    resetScores();
    updateTheme();
    navigate('/', { replace: true });
  }, [navigate]);

  const { oid } = useParams();

  const outcome = useMemo(() => {
    return outcomes[oid];
  }, [oid])

  if (!outcome) {
    return <></>;
  }

  const { stream, content, traits, areas, linkSections } = outcome;

  const titleCase = (str) => str.replace(
    /\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

  const getStream = () => {
    return titleCase(stream == 'people' ? 'people person' : stream);
  }

  const getPrefix = () => {
    return stream == 'leader' || stream == 'people' || stream == 'creative' || stream == 'maker' ? 'a' : 'an';
  }

  const streamTheme = streamThemes?.[stream] || {};

  theme = {
    ...theme,
    ...streamTheme,
    layout: 'image-left',
  } as Theme;

  return (
    <OutcomeStyled style={{ color: theme?.color?.text || '#000' }}>
      <QuestionStyled className='question question--right'>
        <div className='question__content'>
          <h1 style={{ color: theme?.color?.text || '#000' }}>You&apos;re {getPrefix()} <span style={{ color: theme?.color?.accent || theme?.color?.text || '#000' }}>{getStream()}</span></h1>
          {!!content && 
            <div
              className='outcome__description'
              style={{ color: theme?.color?.text || '#000' }}
              dangerouslySetInnerHTML={{__html: content}}
            />
          }
          {!!traits?.length && <>
            <h2>Personality traits</h2>
            <div className='outcome__traits'>
              {traits.map((trait, i) => (
                <Trait
                  key={i}
                  {...trait}
                  color={theme?.color?.text}
                />
              ))}
            </div>
          </>}
          {!!areas?.length && <>
            <h2>Recommended areas of interest</h2>
            <div className='outcome__areas'>
              {areas.map((area, i) => (
                <SketchImage
                  key={i}
                  {...area}
                  outlineColor={theme?.color?.text || '#000'}
                />
              ))}
            </div>
          </>}
          {!!linkSections?.length && <div className='outcome__links'>
            {linkSections.map((section, i) => (
              <Fragment key={i}>           
                <h2>{section?.title}</h2>
                <div>
                  {!!section?.links?.length && section?.links?.map((link, j) => (
                    <a
                      key={j}
                      href={link?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link?.text}
                    </a>
                  ))}
                </div>
              </Fragment>
            ))}
          </div>}
          <SketchButton
            text={'Start again'}
            label={'Start again'}
            textColor={theme?.color?.text}
            textHoverColor={theme?.color?.textHover || '#fff'}
            outlineColor={theme?.color?.text}
            backgroundColor={theme?.color?.accent}
            clickHandler={() => handleOnClick()}
          />
        </div>
        <div className='question__image'>
          <ImageDevice theme={theme} />
        </div>
      </QuestionStyled>
    </OutcomeStyled>
  )
}

export default Outcome;
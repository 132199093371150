import { generateMedia } from 'styled-media-query';

export const BREAKPOINTS = {
  xs: '360px',
  tiny: '420px',
  sm: '600px',
  mobile: '37.5em', // 600px
  smplus1: '601px',
  md: '960px',
  tablet: '60em', // 960px
  mdplus1: '961px',
  mdplusgap: '990px',
  lg: '1280px',
  desktop: '80em', // 1280px
  laptop: '90em',
  xl: '1600px',
  xxl:  '1921px'
};

export const media = generateMedia(BREAKPOINTS);

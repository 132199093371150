import React, { ReactNode, useContext } from 'react'
import { SketchCheckboxStyled } from './SketchCheckbox.styles';
import { ReactSVG } from 'react-svg'
import { SketchButtonProps } from '../SketchButton';
import { GlobalContext } from '../../App';

interface SketchCheckboxProps extends Omit<SketchButtonProps, 'text'> {
  active?: boolean;
  value?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: ReactNode;
}

const SketchCheckbox = ({ label, type = 'button', outlineColor = '#fff', textColor = '#fff', textHoverColor, backgroundColor = '#5824E1', active, value, setValue, children }: SketchCheckboxProps) => {
  if (!textHoverColor) textHoverColor = textColor;

  const { assetUrl } = useContext(GlobalContext);

  return (
    <>
      <SketchCheckboxStyled
        className="sketch__input"
        type={type}
        textColor={textColor}
        textHoverColor={textHoverColor}
      >
        <ReactSVG
          className="sketch-button__background"
          beforeInjection={(svg) => {
            svg.setAttribute('style', `fill: ${textColor}`)
          }}
          src={assetUrl + '/images/buttons/checkbox__box.svg'}
        />
        {children || <input
          type='checkbox'
          aria-label={label}
          onChange={(ev) => { if (setValue) setValue(ev?.target?.checked) }}
        />}
        {(!!value || !!active) && <ReactSVG
          className="sketch-button__outline"
          beforeInjection={(svg) => {
            svg.setAttribute('style', `fill: ${textColor}`)
          }}
          src={assetUrl + '/images/buttons/checkbox__check.svg'}
        />}
      </SketchCheckboxStyled>
    </>
  )
}

export default SketchCheckbox;

import React from 'react';
import { ScaleLoader } from 'react-spinners';
import LoadingStyled from './Loading.styles';

export const Loading = () => (

  <LoadingStyled>
    <ScaleLoader 
      height={35}
      width={5}
      color={'#000'}
      loading={true} // App controls rendering this
    />
  </LoadingStyled>
)
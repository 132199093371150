import React, { useContext } from 'react'
import { SketchButtonQuestionStyled } from './SketchButtonQuestion.styles';
import { ReactSVG } from 'react-svg'
import { SketchButtonProps } from '../SketchButton';
import { GlobalContext } from '../../App';

interface SketchButtonQuestionProps extends SketchButtonProps {
  size?: 'small' | 'medium' | 'large'
}

const SketchButtonQuestion = ({ label, text, type = 'button', outlineColor = '#fff', textColor = '#fff', textHoverColor, backgroundColor = '#5824E1', size = 'small', disabled, clickHandler }: SketchButtonQuestionProps) => {
  if (!textHoverColor) textHoverColor = textColor;

  const { assetUrl } = useContext(GlobalContext);

  return (
    <SketchButtonQuestionStyled
      className={`sketch-button-question sketch-button-question--${size}`}
      aria-label={label || text}
      type={type}
      onClick={clickHandler}
      textHoverColor={textHoverColor}
      disabled={disabled}
    >
      <ReactSVG
        className="sketch-button__background"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${backgroundColor}`)
        }}
        src={assetUrl+ `/images/buttons/question-button__background--${size}.svg`}
      />
      <span style={{ color: textColor }}>
        {text}
      </span>
      <ReactSVG
        className="sketch-button__outline"
        beforeInjection={(svg) => {
          svg.setAttribute('style', `fill: ${outlineColor}`)
        }}
        src={assetUrl+ `/images/buttons/question-button__outline--${size}.svg`}
      />
    </SketchButtonQuestionStyled>
  )
}

export default SketchButtonQuestion;

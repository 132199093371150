import React, { useEffect, useRef, useState } from 'react'
import AnimatedGridStyled from './AnimatedGrid.styles'
import AnimatedGridView from './AnimatedGrid.view'

interface AnimatedGridProps {
  bgColor?: string;
}

// BG color change triggers update due to scope change and recolor
// Color doesnt actual get used as its a fixed grayscale
const AnimatedGrid = ({ bgColor = '#fff' }: AnimatedGridProps) => {
  const canvasRef = useRef(null);
  const [gridView, setGridView] = useState(null);
  const [mounted, setMounted] = useState(false);

  const handleWindowResize = () => {
    gridView?.onWindowResize(window.innerWidth * 1.1, window.innerHeight * 1.1);
  };

  useEffect(() => {
    // Proper lifecycle innit
    if (!mounted) {
      setMounted(true);
      // Create aniamted grid view class
      const grid = new AnimatedGridView(canvasRef.current, window.innerWidth * 1.1, window.innerHeight * 1.1);
      if (bgColor) grid?.updateColor(bgColor);
      setGridView(grid);
    }

    // Init event listeners
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Remove event listeners
      window.removeEventListener('resize', handleWindowResize);
    }
  }, [mounted])

  useEffect(() => {
    if (bgColor) {
      gridView?.updateColor(bgColor);
    }
  }, [bgColor])

  return (
    <AnimatedGridStyled>
      <canvas ref={canvasRef}></canvas>
    </AnimatedGridStyled>
  )
}

export default AnimatedGrid

export const getUnit = (val: string) => {
  const textRegex = /[^0-9.]+/g;
  const result: string = textRegex.exec(val).join();
  return result;
};

export const getValue = (val: string) => {
  const numRegex = /^\D+/g;
  const result: number = parseFloat(val.replace(numRegex, ''));
  return result;
};

export const cacheImages = async (srcArray, baseUrl) => {
  const promises = await srcArray.map((src) => {
    return new Promise<void>((resolve, reject): void => {
      const img = new Image();

      img.src = baseUrl + src;
      img.onload = () => resolve();
      img.onerror = () => reject();
    })
  })

  await Promise.all(promises);
}

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const throttle = (func, delay = 250) => {
  let shouldWait = false

  return (...args) => {
    if (shouldWait) return

    func(...args)
    shouldWait = true
    setTimeout(() => {
      shouldWait = false
    }, delay)
  }
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
import React, { useContext } from 'react';
import { Theme } from 'theme/theme';
import { ImageDeviceStyled } from './ImageDeviceStyled';
import { ReactSVG } from 'react-svg'
import ImageDeviceLockup from '../../atoms/ImageDeviceLockup';
import ImageDeviceAccents from '../../atoms/ImageDeviceAccents';
import { GlobalContext } from '../../App';

interface ImageDeviceProps {
  theme: Theme;
}

export const ImageDevice = ({ theme }: ImageDeviceProps) => {

  const { assetUrl } = useContext(GlobalContext);

  return (
    <ImageDeviceStyled>
      <div className="image-device__shape"> 
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute('style', `fill: ${theme?.color?.accent || '#ffffff'};`)
          }}
          src={assetUrl + theme?.shape?.path}
        />
      </div>
      <div className="image-device__lockup"> 
        <ImageDeviceLockup theme={theme} />
      </div>  
      <div className="image-device__portrait">
        <img src={assetUrl + theme?.portrait?.path} role="presentation"/>
      </div>
      <div className="image-device__accents"> 
        <ImageDeviceAccents theme={theme} />
      </div>    
    </ImageDeviceStyled>
  )

}

export default ImageDevice;

